
import { Box, Typography } from '@mui/material';
import { ResponsiveContainer } from '../../../components/responsive';
import PricingContent from '../components/pricing';
import { SeoHelmet } from '../../../components/seo';

export default function PricingPage(){

    return (
        <>
        <SeoHelmet 
        title="Pricing"
        description="Try Free for 7 days, no credit card required. Pro - $8/mo (annual). Premium - $15/mo (annual)."
        />

        <ResponsiveContainer>
            <Box sx={{width: '100%',textAlign: 'center'}}>
                <Typography gutterBottom variant="h4" >
                    Select a plan to start getting a statistical edge
                </Typography>
            </Box>
            
            <PricingContent />
        </ResponsiveContainer>
        </>
    );
}

