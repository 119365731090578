
import { ResponsiveContainer } from '../../../components/responsive';
import { JoinDiscord } from '../components/join-discord';
import { SeoHelmet } from '../../../components/seo';

export default function Forum(){
    return (
        <>
            <SeoHelmet 
            title="Join our Discord"
            description="Discuss value bets, creating models, model tuning, parlays, and current events in sport."
            />
            <ResponsiveContainer>
                <JoinDiscord />
            </ResponsiveContainer>
        </>
    );
}