import {
    Avatar,
    Box,
    IconButton,
    Stack,
    Typography,
    Link
} from '@mui/material';

import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTokIcon from '../assets/socials/tiktok-white-icon.png';
import FacebookIcon from '@mui/icons-material/Facebook';

const socialLinks = [
    {
        Icon: <InstagramIcon />,
        link: 'https://www.instagram.com/solved_sports'
    },
    {
        Icon: <XIcon />,
        link: 'https://x.com/solvedsports'
    },
    {
        // No tik tok logo in MUI so using our own
        Icon: <Avatar src={TikTokIcon} alt="Tik Tok" sx={{ width: 25, height: 25 }} />,
        link: 'https://www.tiktok.com/@solvedsports'
    },
    {
        Icon: <LinkedInIcon />,
        link: 'https://www.linkedin.com/company/solved-sports/'
    },
    {
        Icon: <FacebookIcon />,
        link: 'https://www.facebook.com/Solved-Sports-61566887965247/'
    }
];

export function Footer() {

    const today = new Date();

    return (
        <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', borderTop: 2, borderColor: "background.paper", mt: 2, pb: 4 }}>
            <footer>
                <Stack direction="column" alignItems="center" spacing={1}>

                    <Stack direction="row" spacing={1}>
                        <Link href="/privacy-policy" sx={{ cursor: 'pointer' }} underline="none" variant="body2">Privacy Policy</Link>
                        <Link href="/terms-of-use" sx={{ cursor: 'pointer' }} underline="none" variant="body2">Terms of Use</Link>
                    </Stack>

                    <Typography variant="body2" gutterBottom>{"Contact: help@solvedsports.com"}</Typography>

                    <Stack direction="row" spacing={1}>
                        {socialLinks.map((item, index) => (
                            <IconButton key={index} target="_blank" href={item.link}>
                                {item.Icon}
                            </IconButton>
                        ))}

                    </Stack>

                    <Box>
                        <Typography variant="body2">Data provided by Stats Perform LLC</Typography>
                    </Box>

                    <Typography variant="body" gutterBottom sx={{ pt: 2 }}>
                        &copy; {today.getFullYear()} Solved Sports LLC
                    </Typography>
                </Stack>
            </footer>
        </Box>

    );

}