
import {useEffect,useState} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useNavigate} from 'react-router-dom';

import {
    Button,
    Grid,
    MenuItem,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';

import { teamModelsRequest, teamStoreRequest } from '../../../../hooks/api/team';
import { playerModelsRequest, playerStoreRequest } from '../../../../hooks/api/player';

import { TeamsPredictionsPopup, PlayerPredictionsPopup } from '../../../predictions/components/common/prediction-popups';
import { ProfileModelRenamePopup } from '../rename-popup';
import { DeleteModelPopup } from '../delete-popup';
import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { MultiSelect } from '../../../../components/menus';

import { ModelCard } from './model-card';

import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { uniqueValuesInDictList } from '../../../../utils/general';


export function ProfileModelsContainer({modelType}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [modelsData, setModelsData] = useState([]);
    const [filteredModelsData,setFilteredModelsData] = useState([]);
    const [loading, setLoading] = useState(true);

    ////////////////////////////////////////////////////////
    // API calls
    ////////////////////////////////////////////////////////

    const handleModelsResponse = (response) => {
      // Set the models information in reverse so the most recent
      // model is first
      setModelsData(response.data.modelsInformation.toReversed());
      setFilteredModelsData(response.data.modelsInformation.toReversed());
      setLoading(false);
    }
    const handleModelsError = (error) => {
      setLoading(false);
    }

    useEffect(() => {
        modelType === 'team' ? 
        teamModelsRequest(handleModelsResponse,handleModelsError)
        :
        playerModelsRequest(handleModelsResponse,handleModelsError)
      }, [loading]);


    const gridSpacing = isMobile ? 1 : 3;

    return (
        <>
        {!loading && filteredModelsData.length === 0 &&
        <Stack width="100%" textAlign="center" alignItems="center" sx={{ p: 4 }}>
            <Typography variant="h6">No saved {modelType} models</Typography>
        </Stack>
        }

        {!loading && filteredModelsData.length !== 0 &&
        <Stack direction="row" justifyContent="flex-end" spacing={1} width="100%" sx={{mb: 2,pr:gridSpacing}}>
            <ProfileModelsSortBy
                filteredModelsData={filteredModelsData}
                setFilteredModelsData={setFilteredModelsData}
            />
            <ProfileModelsFilter
                modelType={modelType}
                modelsData={modelsData}
                setFilteredModelsData={setFilteredModelsData}
            />
        </Stack>
        }

        <Grid container spacing={gridSpacing} direction="row"
        sx={{pr: gridSpacing, pb: gridSpacing}}
        >
            {loading ? 
            <GridItemsLoadingDisplay /> 
            : 
            <ProfileModelsContent
            modelType={modelType}
            filteredModelsData={filteredModelsData}
            setLoading={setLoading} 
            />
            }
        </Grid>
        </>
    );

}

function GridItemsLoadingDisplay(){

    // Create arbitrary list to iterate over
    // and create Grid items
    const gridItems = Array(6).fill(1);

    return (
        <>
        {gridItems.map((item, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
                <Skeleton
                variant="rounded"
                height={0}
                sx={{pt: '100%'}}
                />
            </Grid>
        ))}
        
        </>
    )
}

function ProfileModelsFilter({modelType,modelsData,setFilteredModelsData}){

    ////////////////////////////////////////////////////////
    // Filters
    ////////////////////////////////////////////////////////
    const uniqueLeagues = uniqueValuesInDictList(modelsData,"league");
    const [selectedLeagues,setSelectedLeagues] = useState(uniqueLeagues);
    const leagueFilterFn = ({league}) => selectedLeagues.includes(league);

    const uniquePositions = uniqueValuesInDictList(modelsData,"position");
    const [selectedPositions,setSelectedPositions] = useState(uniquePositions);
    const positionFilterFn = ({position}) => selectedPositions.includes(position);

    const uniqueProps = uniqueValuesInDictList(modelsData,"prop");
    const [selectedProp,setSelectedProp] = useState(uniqueProps);
    const propFilterFn = ({prop}) => selectedProp.includes(prop);

    useEffect(() => {
        let filteredData = modelsData.filter(leagueFilterFn);
        filteredData = filteredData.filter(positionFilterFn);
        filteredData = filteredData.filter(propFilterFn);

        setFilteredModelsData(filteredData);
     },[selectedLeagues,selectedPositions,selectedProp]);

    const handleFilterMenuReset = () => {
        setFilteredModelsData(modelsData);
        setSelectedLeagues(uniqueLeagues);
        setSelectedPositions(uniquePositions);
        setSelectedProp(uniqueProps);
    }

    return (
        <FilterMenu>
            <MenuItem>
                <MultiSelect
                    label="League"
                    values={selectedLeagues}
                    setValues={setSelectedLeagues}
                    availableValues={uniqueLeagues}
                />
            </MenuItem>
            {modelType === 'player' &&
            <MenuItem>
                <MultiSelect
                    label="Position"
                    values={selectedPositions}
                    setValues={setSelectedPositions}
                    availableValues={uniquePositions}
                />
            </MenuItem>
            }
            {modelType === 'player' &&
            <MenuItem>
                <MultiSelect
                    label="Prop"
                    values={selectedProp}
                    setValues={setSelectedProp}
                    availableValues={uniqueProps}
                />
            </MenuItem>
            }
            <MenuItem sx={{justifyContent: 'center'}}>
                <Button variant="contained" size="small" sx={{textTransform: 'none'}}onClick={handleFilterMenuReset}>
                    Reset
                </Button>
            </MenuItem>
        </FilterMenu>
    )
}

function ProfileModelsSortBy({filteredModelsData,setFilteredModelsData}){

    ////////////////////////////////////////////////////////
    // Sort functions
    ////////////////////////////////////////////////////////

    const options = [
        {
            title: "Most Recent",
            sortFn: (a,b) => 
                descendingSortFunction(a.index,b.index)
        },
        {
            title: "Least Recent",
            sortFn: (a,b) => 
                ascendingSortFunction(a.index,b.index)
        },
        {
            title: "Highest Grade",
            sortFn: (a,b) => 
                descendingSortFunction(a.overall_grade,b.overall_grade)
        },
        {
            title: "Lowest Grade",
            sortFn: (a,b) => 
                ascendingSortFunction(a.overall_grade,b.overall_grade)
        },
    ]

    const [selectedIndex,setSelectedIndex] = useState(0);
    
    const sortData = (sortFn) => {
        const localFilteredData = filteredModelsData.slice().sort(sortFn);
        setFilteredModelsData(localFilteredData);
    }

    const handleChange = (event) => {
        const index = event.currentTarget.value;
        sortData(options[index].sortFn);
        setSelectedIndex(index);
    }

    return (
        <SortByMenu
        selectedIndex={selectedIndex}
        options={options}
        onChangeFn={handleChange}
        />
    )
}

function ProfileModelsContent({modelType,filteredModelsData,setLoading}){

    const navigate = useNavigate();

    const [selectedModelIndex,setSelectedModelIndex] = useState(false);
    const [selectedModelName,setSelectedModelName] = useState("");
    const [selectedLeague,setSelectedLeague] = useState(null);
    const [selectedPosition,setSelectedPosition] = useState(null);
    const [selectedProp,setSelectedProp] = useState(null);

    ////////////////////////////////////////////////////////
    // Popup States
    ////////////////////////////////////////////////////////

    const [renamePopupOpen,setRenamePopupOpen] = useState(false);
    const [deletePopupOpen,setDeletePopupOpen] = useState(false);
    const [predictionsPopupOpen, setPredictionsPopupOpen] = useState(false);

    const handleRename = (modelIndex,modelName) => {
        setSelectedModelName(modelName);
        setSelectedModelIndex(modelIndex);
        setRenamePopupOpen(true);
    }

    const handleDelete = (modelIndex) => {
        setSelectedModelIndex(modelIndex);
        setDeletePopupOpen(true);
    }

    const handleEdit = (modelIndex) => {
        // Find model matching index and set useStates with information
        const model = findModelByIndex(modelIndex);

        // Set necessary fields before processing
        setSelectedLeague(model.league);

        const params = {
            "index":modelIndex
        }


        // Define locally to have access to model.league for redirect
        const handleStoreResponseForEdit = (response) => {
            navigate(`/create-${modelType}-model/${model.league}`, {state : {edit_enabled : true}});
        }
        const handleStoreErrorForEdit= (error) => {}

        // Set model id in session
        modelType === 'team' ?
        teamStoreRequest(params,handleStoreResponseForEdit,handleStoreErrorForEdit)
        :
        playerStoreRequest(params,handleStoreResponseForEdit,handleStoreErrorForEdit)
        
    }

    const handleStoreResponseForSummary = (response) => {
      navigate(`/model-summary/${modelType}`);
    }
    const handleStoreErrorForSummary = (error) => {}

    const handleSummary = (model_index) => {

        const params = {
            "index":model_index
        }

        // Set model id in session
        modelType === 'team' ?
        teamStoreRequest(params,handleStoreResponseForSummary,handleStoreErrorForSummary)
        :
        playerStoreRequest(params,handleStoreResponseForSummary,handleStoreErrorForSummary)
    }

    const handlePredictions = (model_index) => {
        // Find model matching index and set useStates with information
        const model = findModelByIndex(model_index);

        // Set necessary fields before opening popup
        setSelectedLeague(model.league);
        setSelectedPosition(model.position); // Will just be null if team model
        setSelectedProp(model.prop); // Will just be null if team model
        setSelectedModelIndex(model_index);
  
        setPredictionsPopupOpen(true);
    };

    const createInfoDisplayName = (model) => {
        let displayStr  = '';
        if (modelType === 'team') {
            displayStr = model.league;
        } else {
            displayStr = [model.position,model.prop,model.league].join(' | ');
        }
        return displayStr;
    }

    const findModelByIndex = (model_index) => {
        let model = {};
        for(var i=0; i < filteredModelsData.length; i++){
            if(filteredModelsData[i].index === model_index){
                model = filteredModelsData[i];
                break;
            }
        }

        return model;
    }

    return (
        <>
        {filteredModelsData.map((model, index) => (
            <Grid item xs={12} sm={6} lg={4} key={model.name+index}>
                <ModelCard
                model_index={model.index}
                info_display={createInfoDisplayName(model)}
                league={model.league}
                model_name={model.name}
                created={model.created}
                overall_grade={model.overall_grade}

                handleEdit={handleEdit}
                handleRename={handleRename}
                handleDelete={handleDelete}

                handleSummary={handleSummary}
                handlePredictions={handlePredictions}
                />
            </Grid>
        ))}

        <ProfileModelRenamePopup open={renamePopupOpen} setOpen={setRenamePopupOpen} modelType={modelType} currentModelName={selectedModelName}
            modelIndex={selectedModelIndex} setLoading={setLoading} />
        <DeleteModelPopup open={deletePopupOpen} setOpen={setDeletePopupOpen}  modelType={modelType} modelIndex={selectedModelIndex} 
            setLoading={setLoading} />
        {modelType === 'team' ?
        <TeamsPredictionsPopup league={selectedLeague} modelIndex={selectedModelIndex} open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen} />
        :
        <PlayerPredictionsPopup league={selectedLeague} position={selectedPosition} prop={selectedProp} 
        modelIndex={selectedModelIndex} open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen}
        />
        }
        </>
    )
}