import { useNavigate } from 'react-router-dom';
import { 
    Card,
    CardActionArea,
    CardHeader,
    CardMedia,
    Skeleton
} from '@mui/material';
import dayjs from 'dayjs';

export function ArticlesLoadingDisplay(){

    // Create arbitrary list to iterate over
    // and create items
    const loadingItems = Array(4).fill(1);

    return (
        <>
        {loadingItems.map((item, index) => (
                <Skeleton
                key={index}
                variant="rounded"
                height={300}
                />
        ))}
        </>
    )
}

export function ArticleCard({id,title,date,preview}){

    const navigate = useNavigate();

    return (
        <Card sx={{borderRadius: 2, boxShadow: 3}}>
            <CardActionArea
            onClick={()=>{navigate('/blog/'+id)}}
            >
                <CardMedia
                component="img"
                height="300"
                image={process.env.REACT_APP_BLOG_API_URL+"/assets/"+preview}
                alt={"No Preview for "+title}
                />

                <CardHeader
                    title={title}
                    subheader={dayjs(date).format('MMM Do, YYYY')}
                    sx={{height: 150,alignItems: 'flex-start'}}
                />

            </CardActionArea>
        </Card>
    )
}