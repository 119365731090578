import { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress, 
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import HelpIcon from '@mui/icons-material/Help';

import { LoadingPopup } from './info';
import { tableHeight } from '../../../../components/table';
import { StatCount } from '../../../../components/status';

const mobileSelectedStatsHeight = 300;

function SelectedStats({rowSelection,setRowSelection,isMobile}){

  const handleDeleteFromSelectStats = (statToRemove) => () => {

    // Remove stat from rowSelection state by using a copy
    const copyRowSelection = {...rowSelection}
    delete copyRowSelection[statToRemove];
    setRowSelection( copyRowSelection );
  }

  return(
    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">

      {Object.keys(rowSelection).map((item,index) => (
          <Chip key={index}
          label={item}
          onDelete={handleDeleteFromSelectStats(item)}
          size={isMobile ? "small" : "medium"}
          />
      ))}

    </Stack>
  );
}

export function SelectedStatsContainer({rowSelection,setRowSelection,isTraining}){

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loadingPopupOpen,setLoadingPopupOpen] = useState(false);

  return (
    <>
    <Stack direction="column" alignItems="center" textAlign="center" 
    spacing={2} sx={{height: largeScreen ? tableHeight : mobileSelectedStatsHeight}}
    >
      {isTraining ? (
          <>
              <Typography variant="h4">Generating Model...</Typography>
              <Typography variant="body" gutterBottom>This could take up to 30 seconds. Please do not close this window.</Typography>
              <CircularProgress sx={{pb: 4}}/>
              <Button 
              onClick={()=>setLoadingPopupOpen(true)}
              startIcon={<HelpIcon />}
              >
                Why does this take so long
              </Button>
          </>
          
          ) : (
          <>
            <Stack alignItems="center" sx={{ width: '100%', minHeight: '0', flexGrow: 1 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h5">Selected Statistics</Typography>
                <StatCount count={Object.keys(rowSelection).length}/>
              </Stack>
              <Box component="div" className='onboarding-step-2'
                sx={{ p: 1, borderRadius: 2, width: '100%', height: '100%', overflowY: 'scroll', backgroundColor: `${theme.palette.background.paper}` }}>
                <SelectedStats rowSelection={rowSelection} setRowSelection={setRowSelection} isMobile={isMobile} />
              </Box>
            </Stack>
          </>
      )}
    </Stack>
    <LoadingPopup open={loadingPopupOpen} setOpen={setLoadingPopupOpen} />
    </>
  )
}

export function CategoriesStack({row,isMobile}){

  return (
    <Stack direction="row" spacing={0.2} useFlexGap flexWrap="wrap" sx={{width: '100%', mb: isMobile ? 2:0 }}>
      {
        row.original.category.map((item,index) => (
          <Chip key={index}
          label={item}
          size={isMobile ? "small" : "medium"}
          />
        ))
      }

    </Stack>
  )
}

