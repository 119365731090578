import {
    Box
} from '@mui/material';
  
const images = require.context('../../assets/graphics/changelog', false, /\.png$/);

export function ChangelogGraphic(props){

    let logoSrc;
    try {
        logoSrc = images(props.file);
    }
    catch {
        logoSrc = null;
    }

    return (
        <Box
            component="img"
            alt="visual"
            src={logoSrc}
            {...props}
        />
    )
}