
import { useTheme } from '@mui/material/styles';
import { useState,useEffect } from 'react';
import Joyride from 'react-joyride';

import {
    Button
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { accountPreferencesGetRequest, accountPreferencesPutRequest } from '../../hooks/api/account';

function joyrideStyle(theme) {
    return {

        // Color of small arrow at the bottom of popup that points to 
        // highlighted component
        // Default: '#fff'
        arrowColor: '#fff',

        // Background color of popup
        // Default: '#fff'
        backgroundColor: '#fff',

        // Not using beacon
        beaconSize: 36,
        
        // Background color of unselected parts of the page
        // Default: 'rgba(0, 0, 0, 0.5)'
        overlayColor: 'rgba(0, 0, 0, 0.5)',

        // Color of buttons
        // Default: '#f04' (red)
        primaryColor: theme.palette.primary.main,

        // Shadow of spotlight. Not using spotlight.
        // Default: '0 0 15px rgba(0, 0, 0, 0.5)'
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',

        // Color of text
        // Default: '#333' (black)
        textColor: '#333',

        // I am not sure what this does
        // Default: 100
        zIndex: 100,
    }
}

export function Walkthrough({steps,preference}) {

    // Add reference to question button as final step
    useEffect(() => {
        steps.push({
            "target"  : ".onboarding-step-final",
            "content" : "Click this button to view this tutorial again",
            "disableBeacon": true
        })
    },[steps]);

    const [run,setRun] = useState(false);
    const [updateUserPreference,setUpdateUserPreference] = useState(false);
    
    const theme = useTheme();

    const handleJoyrideCallback = (data) => {
        const { action, index, origin, status, type } = data;

        // Walkthrough is done
        if (action === "reset" || action === "close"){
            // If walkthrough preference isn't already disabled, disable it
            setRun(false);
            if (updateUserPreference) {
                disableOnboardingPreference();
            }
            
        }
    }

    const handleGetPreferencesResponse = (response) => {
        // Send an API request to update preference if user logged in (found) and it is enabled
        setUpdateUserPreference(response.data.preference_found && response.data.preference_enabled);
        setRun(response.data.preference_enabled);
    }
    const handleGetPreferencesError = (error) => {
        // It is expected behavior for this API request to
        // be rejected for CSRF validation failure (400)
        // This is a special case for this page which users are
        // allowed to visit without being logged in
        if (error.response.data.csrf) {
            setRun(true);
            setUpdateUserPreference(false);
        }
    }
    
    // Pause 1.5 seconds then start onboarding if enabled
    useEffect(() => {
        const params = {
            'preference' : preference
        }
        const timeout = setTimeout(() => {
            accountPreferencesGetRequest(params,handleGetPreferencesResponse,handleGetPreferencesError);
        }, 1500);
    
        return () => clearTimeout(timeout); 
    }, []);

    const handlePutPreferencesResponse = (response) => {
        setUpdateUserPreference(false);
    }
    const handlePutPreferencesError = (error) => {

    }

    const disableOnboardingPreference = () => {
        const params = {
            'preference' : preference,
            'enabled' : false
        }
        accountPreferencesPutRequest(params,handlePutPreferencesResponse,handlePutPreferencesError);
    };

    return(
        <>
            <Button variant='text' size="medium" onClick={() => {setRun(true)}} className="onboarding-step-final">
                <HelpIcon fontSize="large" sx={{color: theme.palette.primary.main}}/>
            </Button>
            {run &&
            <Joyride steps={steps} continuous={true} run={run} showProgress={true} showSkipButton={true}
            callback={handleJoyrideCallback}
            scrollOffset={200} // Make this large number to prevent scrolling past popup
            locale={{
                'last': 'Close'
            }}
            styles={{
                options: joyrideStyle(theme)
            }}
            />
            }
        </>
    )

}

export function CreateTeamModelWalkthrough() {

    const steps = [
        {
          "target"  : ".onboarding-step-1",
          "content" : "Select stats from list. Search or filter by category.",
          "disableBeacon": true
        },
        {
          "target"  : ".onboarding-step-2",
          "content" : "Selected stats will be shown here",
          "disableBeacon": true
        },
        {
          "target"  : ".onboarding-step-3",
          "content" : "Optionally, for more advanced customization, adjust the machine learning hyperparameters",
          "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-4",
            "content" : "Create model by clicking Run",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-5",
            "content" : "We highly recommend looking at our FAQs section before creating your first model",
            "disableBeacon": true
        }
      ]

      return (
        <>
            <Walkthrough 
                steps = {steps}
                preference={'create_team_model_page_onboarding_enabled'}
            />
        </>
      )

}

export function CreatePlayerModelWalkthrough() {

    const steps = [
        {
            "target"  : ".onboarding-step-1",
            "content" : "Select a position and prop to predict to create a model.",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-2",
            "content" : "Select stats from list. Search or filter by category.",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-3",
            "content" : "Selected stats will be shown here",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-4",
            "content" : "Optionally, for more advanced customization, adjust the machine learning hyperparameters",
            "disableBeacon": true
          },
        {
            "target"  : ".onboarding-step-5",
            "content" : "Create model by clicking Run",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-6",
            "content" : "We highly recommend looking at our FAQs section before creating your first model",
            "disableBeacon": true
        }
      ]

      return (
        <>
            <Walkthrough 
                steps = {steps}
                preference={'create_player_model_page_onboarding_enabled'}
            />
        </>
      )

}