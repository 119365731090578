
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge, 
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { teamExpertRequest } from '../../../../hooks/api/team';

export function TeamExpertModelButton({league,setUserPopupOpen,setPricingPopupOpen}){

    const navigate = useNavigate();

    const [loading,setLoading] = useState(false);

    const handleResponse = (response) => {
        setLoading(false);
        navigate('/model-summary/team');
      }
    
      const handleError = (error) => {
        if (error.response) {
          if (error.response.data.csrf) {
            setLoading(false);
            setUserPopupOpen(true);
          } else if (error.response.status == 401 ) {
            // Unauthorized
            setLoading(false);
            setPricingPopupOpen(true);
          }
          else {
            // Something went wrong
          }
        }
      }

    const handleExpertModelButtonClicked = () => {
        setLoading(true);
        const params = {league : league};
        teamExpertRequest(params,handleResponse,handleError);
    }


    return (
        <Badge badgeContent={"BETA"} color="secondary" >
            <LoadingButton
            variant="contained"
            loading={loading}
            startIcon={<AutoAwesomeIcon />}
            onClick={()=>handleExpertModelButtonClicked()}
            >
                Use Expert Model
            </LoadingButton>
        </Badge>
    )
}