// Basic Component Imports
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Skeleton, Stack } from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { PlayerStatSelection } from '../components/player/player-stat-selection-table';
import { CreatePlayerModelFAQs } from '../components/player/faqs';
import { CreatePlayerModelWalkthrough } from '../../../components/onboarding/walkthroughs';
import { CreatePlayerModelHeader } from '../components/common/headers';
import { SeoHelmet } from '../../../components/seo';

export default function CreatePlayerModel() {
    const { target } = useParams();

    const location = useLocation();

    const [editEnabled,setEditEnabled] = useState(false);

    // Loading the location state takes some time to render
    // and components are rendering before it is done, so this
    // state is used to prevent that
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        setEditEnabled(location.state !== null ? location.state.edit_enabled : false);
        setLoading(false)
    },[location]);

    return (
        <>
            <SeoHelmet 
            title="Create Player Prop Model"
            description="Create a custom betting model to predict player props for NFL and NBA."
            />
            <ResponsiveContainer>
                <CreatePlayerModelHeader league={target} />
                <Stack direction="row" justifyContent="flex-end">
                    <CreatePlayerModelWalkthrough />
                </Stack>
                {loading ?
                <Skeleton variant="rectangular" animation="wave" width="100%" height={600}/>
                :
                <PlayerStatSelection 
                league={target} 
                editEnabled={editEnabled} 
                setEditEnabled={setEditEnabled}
                />
                }
                <CreatePlayerModelFAQs />
            </ResponsiveContainer>
        </>
    );
}