import {
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export function AnalyticsContainer(){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Stack direction="column" spacing={2} alignItems="center" 
        sx={{p: {xs:4,md:8}, borderRadius: 4, backgroundColor: 'background.paper', boxShadow: 2 ,width:"100%", 
        maxWidth: theme.breakpoints.values.lg, }}
        >
            {!largeScreen && <Typography variant="h4">
                By the numbers
            </Typography>
            }
            <AnalyticsDisplay largeScreen={largeScreen}/>
        </Stack>
    )
}

export function AnalyticsDisplay({largeScreen}){

    return (
        <Stack 
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={10}
        sx={{
            borderTop: 2, borderBottom: 2, borderColor: "background.alternate_paper",
            p: 3
        }}
        width="100%"
        >
            {largeScreen && 
            <Typography variant="h4">
                By the numbers
            </Typography>
            }
            <Stack 
            direction={{xs:"column",sm:"row"}}
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={3}
            >
                <NumberDisplay 
                title="67%"
                subtitle="avg expert model historical winning percentage"
                />
                <NumberDisplay 
                title="Trillions"
                subtitle="of statistical combinations to create your own models"
                />
                <NumberDisplay 
                title="10+"
                subtitle="years of historical data for pro and collegiate leagues"
                />
            </Stack>
        </Stack>
    )
}

function NumberDisplay({title,subtitle}){

    return (
        <Stack direction="column" textAlign="center" sx={{maxWidth: 200}}>
            <Typography variant="h3" color="secondary.main">
                {title}
            </Typography>
            <Typography variant="subtitle">
                {subtitle}
            </Typography>
        </Stack>
    )
}