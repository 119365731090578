import {
    Button,
    Container,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BuildIcon from '@mui/icons-material/Build';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PeopleIcon from '@mui/icons-material/People';


export function FeatureCard({Icon,title,subtitle}){

    const theme = useTheme();

    return (
        <Stack direction="column" spacing={2} alignItems="center" textAlign="center" justifyContent="center"
        sx={{borderRadius: 4, backgroundColor: "background.paper", p: 2, pb: 4, pt: 4, height: 250,
            boxShadow: 2,
            // boxShadow: `0px 0px 1px 1px ${theme.palette.primary.main}`,
            border: 3, borderColor: "background.alternate_paper"

        }}
        >
            <Button component="div" disableRipple disableFocusRipple sx={{height: 60, width: 60,
                borderRadius: 11,
                backgroundColor: "primary.main"
                }}>
                <Icon fontSize="large" />
            </Button>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body">{subtitle}</Typography>
        </Stack>
    )
}

const featuresLandingPge = [
    {
        Icon: BuildIcon,
        title: 'Custom Model Building',
        subtitle: 'Simply select the stats you want your models to use'
    },
    {
        Icon: TrendingUpIcon,
        title: 'Historical Profitability',
        subtitle: 'Prove a model\'s profitability from back testing on historical data'
    },
    {
        Icon: AssessmentIcon,
        title: 'ML Model Grade',
        subtitle: 'Advanced machine learning metrics to summarize model performance'
    },
    {
        Icon: EmojiEventsIcon,
        title: 'Betting Predictions',
        subtitle: 'Generate data-driven picks for every game using your custom model'
    },
    {
        Icon: AutoAwesomeIcon,
        title: 'Expert Models',
        subtitle: 'Use our Solved Sports expert model created by our team of data scientists'
    },
    {
        Icon: PeopleIcon,
        title: 'Community Resources',
        subtitle: 'Join our Discord, or read our blog, for free access to analytical insights'
    },
]

export function LandingPageFeatures(){


    return (
        <Grid container maxWidth="lg" direction="row" justifyContent="center">
            {featuresLandingPge.map((feature, index) => (

                // Bit of hackery here to remove side padding on mobile but keep vertical padding same always
                <Grid item key={index} xs={12} md={4} sx={{p: 2, pl: {xs: 0, md: 2}, pr: {xs: 0, md: 2}  }}>
                    <FeatureCard
                    Icon={feature.Icon}
                    title={feature.title}
                    subtitle={feature.subtitle}
                    />
                </Grid>
            
            ))}
        </Grid>
    )
}

export function LandingPageFeaturesContainer(){

    return (
        <Container maxWidth="lg" disableGutters id="features">
            <Stack direction="column" spacing={2} sx={{p: 1}}>
                <Typography variant="h3" textAlign="center" >Why Solved Sports?</Typography>
                <Typography variant="body" textAlign="center" >Our team of data scientists is constantly adding to the below features</Typography>
            </Stack>
            <LandingPageFeatures />
        </Container>
    )
}