
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
}    
from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export function FeatureSummary({features,comingSoonFeautures=[]})
{

  return (
    <>
      <List>
      {
        features.map((item,index) => (
          <ListItem disablePadding key={index}>
              <ListItemIcon>
                { item.enabled ? 
                  <CheckCircleOutlineIcon color="success" /> 
                : 
                  <HighlightOffOutlinedIcon color="error" />
                }
              </ListItemIcon>
              <ListItemText primary={item.feature} />
          </ListItem>
        ))
      }
      {comingSoonFeautures.length !== 0 &&
      <>
      <ListItem sx={{pl:0,pr:0}}>
        <Divider width="100%" />
      </ListItem>
      <ListItem disablePadding>
          <ListItemText primary={"Coming Soon..."} />
      </ListItem>
      </>
      }
      {
        comingSoonFeautures.map((item,index) => (
          <ListItem disablePadding key={index+50}>
              <ListItemIcon>
                { item.enabled ? 
                  <CheckCircleOutlineIcon color="success" /> 
                : 
                  <HighlightOffOutlinedIcon color="error" />
                }
              </ListItemIcon>
              <ListItemText primary={item.feature} />
          </ListItem>
        ))
      }
      </List>
    </>
  )
}