import { useParams } from 'react-router-dom';
import { 
    Container,
    Skeleton,
    Stack
} from '@mui/material';
import ReactGA from 'react-ga4';

import { ResponsiveContainer } from "../../../components/responsive";
import { ArticleRender } from "../components/article-render";
import { useEffect, useState } from 'react';
import { blogArticleDataRequest } from '../../../hooks/blog-api';
import { ErrorDisplay } from '../../../components/status';
import { SeoHelmet } from '../../../components/seo';


export default function BlogArticlePage(){

    const [error,setError] = useState(null);

    const { id } = useParams();

    if (!id) {
        // Should never occur
        setError("Something went wrong with this article");
    }

    const [article,setArticle] = useState({});
    const [loading,setLoading] = useState(true);

    twttr.ready(
        function (twttr) {
            twttr.widgets.load();
        }
    );

    const handleResponse = (data) => {
        setArticle(data);
        setLoading(false);

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: data.title,
        });
    }
    const handleError= (error) => {
        //Article does not exist, display something went wrong
        setError("Something went wrong fetching this article, it might not exist anymore");

        setLoading(false)
    }

    useEffect(() => {    
        blogArticleDataRequest(id,{},handleResponse,handleError);
    }, []);

    return(
        <>
        {!loading && article.title !== null &&
            <SeoHelmet 
            title={article.title}
            description={article.title}
            url={process.env.REACT_APP_BASE_URL + "/blog"}
            type="article"
            />
        }
        <ResponsiveContainer >
            <Container maxWidth="md" sx={{backgroundColor: "background.paper", borderRadius: 2, p: 3, boxShadow: 2}}>
                {loading &&
                <Stack direction="column" spacing={1}>
                    <Skeleton
                    variant="rounded"
                    height={"5vh"}
                    />
                    <Skeleton
                    variant="rounded"
                    height={"60vh"}
                    />
                </Stack>
                }

                {!loading && error === null && <ArticleRender article={article} />}

                {error &&
                <ErrorDisplay 
                error={error}
                />
                }
            </Container>
        </ResponsiveContainer>
        </>
    )
}