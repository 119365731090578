import { createDirectus, rest, readItem, readItems } from '@directus/sdk';

// Client with REST support
const blogApiClient = createDirectus(`${process.env.REACT_APP_BLOG_API_URL}`).with(rest());


export const blogArticlesRequest = (handleResponse,handleError) => {
    blogApiClient.request(
    readItems('articles', 
        {
            filter: {
                status: {
                    _in: ['published'],
                }
            },
            fields : ['id','title','date_created','date_updated','preview'],
            sort: ['sort', '-date_updated'], // Sort by last updated date (descending)
        }
    ))
    .then((data) => {
        handleResponse(data);
    })
    .catch((error) => {
        handleError(error);
    });
} 

export const blogArticleDataRequest = (id,queryParameters,handleResponse,handleError) => {
    blogApiClient.request(
    readItem('articles', id, queryParameters))
    .then((data) => {
        handleResponse(data);
    })
    .catch((error) => {
        handleError(error);
    });
} 
