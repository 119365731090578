import { useEffect, useMemo, useState } from 'react';
import {
    Box, 
    Button, 
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Skeleton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

import { UserPopup } from '../../../auth/components/popups';
import { StatSelectionMRT, tableHeight } from '../../../../components/table';
import PaymentOptionsPopup from '../../../pricing/components/payment-popup';

import { playerSlimSummaryRequest, playerStatisticsRequest, playerTrainRequest } from '../../../../hooks/api/player';
import { SelectedStatsContainer, CategoriesStack } from '../common/stat-selection';
import { HyperParametersContent } from '../common/hyperparameters';
import { SomethingWentWrongPopup } from '../../../../components/responsive';

function PositionPropDropdowns(
    {position,handlePositionDropdownChanged,availablePositions,prop,setProp,availableProps,loading}
  ){
  
    return (
      <>
        {loading ?
        <>
        <Grid item minWidth={80}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"100%"}/>
        </Grid>
        <Grid item minWidth={200}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"100%"}/>
        </Grid>
        </>
        :
        <>
        <Grid item minWidth={80}>
            <FormControl fullWidth>
                <InputLabel id="position-select-label">Position</InputLabel>
                <Select
                labelId="position-select-label-select"
                id="position-select-label-select"
                value={position}
                label="Position"
                onChange={handlePositionDropdownChanged}
                >
                {availablePositions.map((item,index) => (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item minWidth={200}>
            <FormControl fullWidth>
                <InputLabel id="statistic-label-select-label">Prop</InputLabel>
                <Select
                labelId="statistic-label-label-select"
                id="statistic-label-select-label-select"
                value={prop}
                label="Prop"
                onChange={(event) => {setProp(event.target.value)}}
                >
                {availableProps.map((item,index) => (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        </>
        
        }
      </>
    )
  }

export function PlayerStatSelection({league,editEnabled,setEditEnabled}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  
    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
  
    // Table states and loading states
    const [rowSelection, setRowSelection] = useState({});
    const [loading,setLoading] = useState(true);
    const [isTraining, setIsTraining] = useState(false); 
  
    // Store stats for all positions. Copy of API data.
    const [statsDataAllPositions,setStatsDataAllPositions] = useState({});
  
    // Store stats for the single position being used by table
    const [statsData, setStatsData] = useState([]);
  
    // Store categories for all positions. Copy of API data.
    const [categoriesAllPositions, setCategoriesAllPositions] = useState({});
  
    // Store categories for the single position being used by table
    const [categories, setCategories] = useState([]);
  
    // Store position labels for all position. Copy of API data.
    const [positionLabelsDataAllPositions, setPositionLabelsDataAllPositions] = useState({});
  
    // Store available positions
    const [availablePositions,setAvailablePositions] = useState([]);
  
    // Track position being used by table. Updated from dropdown.
    const [position,setPosition] = useState("");
  
    // Store available props for single position being used by table
    const [availableProps,setAvailableProps] = useState([]);
    
    // Track single statistic prop being used by table currently. Updated from dropdown.
    const [prop,setProp] = useState("");
  
    // Popups
    const [userPopupOpen,setUserPopupOpen] = useState(false);
    const [pricingPopupOpen,setPricingPopupOpen] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);

    // Hyperparameters
    const [customHyperparametersEnabled,setCustomHyperparametersEnabled] = useState(false);
    const [hyperparametersFetched,setHyperparametersFetched] = useState(false);
    const [hyperparameters,setHyperparameters] = useState(
      {
          "layer1_nodes": 16,
          "layer1_activation": "relu",
          "layer1_kernel_regularizer_l1": 0.0,
          "layer1_kernel_regularizer_l2": 0.0,
          "layer1_bias_regularizer_l1": 0.0,
          "layer1_bias_regularizer_l2": 0.0,
          "layer1_activity_regularizer_l1": 0.0,
          "layer1_activity_regularizer_l2": 0.0,

          "useLayer2": true,
          "layer2_nodes": 16,
          "layer2_activation": "relu",
          "layer2_kernel_regularizer_l1": 0.0,
          "layer2_kernel_regularizer_l2": 0.0,
          "layer2_bias_regularizer_l1": 0.0,
          "layer2_bias_regularizer_l2": 0.0,
          "layer2_activity_regularizer_l1": 0.0,
          "layer2_activity_regularizer_l2": 0.0,

          "learning_rate": 0.001,
          "optimizer": "Adam",
          "loss_function": "huber",
          "loss_metrics": [
              "mean_absolute_error"
          ],
          "train_batch_size": 500,
          "epochs": 200,
          "validation_split": 0.2,

          "train_verbose_enabled": false,
          "test_verbose_enabled": false,

          "test_batch_size": 100

      }
    );
  
    ////////////////////////////////////////////////////////
    // API
    ////////////////////////////////////////////////////////
  
    const handleStatisticsResponse = (response) => {
      const localApiStatsDataAllPositions = response.data.stats;
      setStatsDataAllPositions(localApiStatsDataAllPositions);
  
      const localApiStatCategoriesAllPositions = response.data.categories;
      setCategoriesAllPositions(localApiStatCategoriesAllPositions);
  
      const localPositionLabelsData = response.data.labels_by_position;
      setPositionLabelsDataAllPositions(localPositionLabelsData);
  
      // Set default position as the first position in the data
      const localPosition = Object.keys(localApiStatsDataAllPositions)[0];
  
      // Set stats data for single default position
      setStatsData(localApiStatsDataAllPositions[localPosition]);
  
      // Set categories for single default position
      setCategories(localApiStatCategoriesAllPositions[localPosition]);
  
      // Need to set position before availablePositions to prevent menu item
      // from rendering before the default selection is set. If the default selection (position),
      // is set before the available menu items (availablePositions). The menu will attempt to
      // index into {} with the position causing and error.
      setPosition(localPosition);
      setAvailablePositions(Object.keys(localPositionLabelsData));
  
      setProp(localPositionLabelsData[localPosition][0]);
      setAvailableProps(localPositionLabelsData[localPosition]);

      // So this looks ugly but it is the only way to implement this so that the summary handler
      // functions have access to the available stats API response data. The states will not update
      // for the summary handlers if the functions are not defined in here.
      const handleSummaryResponse = (response) => {

        const localPosition = response.data.position;
  
        // Update position of table
        setPosition(localPosition);
    
        // Update stats data being used by table based on selected position
        setStatsData(localApiStatsDataAllPositions[localPosition]);
    
        // Update categories being used by table based on selected position
        setCategories(localApiStatCategoriesAllPositions[localPosition]);
    
        // Order matters here
        setProp(response.data.prop);
        setAvailableProps(localPositionLabelsData[localPosition]);
        
        // Set selected stats based on model
        const statRowSelection = {};
        for (let i = 0; i < response.data.selected_stats.length; i++) {
          statRowSelection[response.data.selected_stats[i]] = true;
        }
        setRowSelection(statRowSelection);

        // Set hyperparameters if custom values were used
        // Not checking === true right now because this field does not exist, in production
        // code this should check if true
        if (response.data.custom_hyperparameters_enabled) {
          setHyperparameters(response.data.hyperparameters);
          setHyperparametersFetched(true); // Set this to prevent a re-fetch of defaults when we enable custom hyperparameters
          setCustomHyperparametersEnabled(true);
        }

        setLoading(false);
      }
      const handleSummaryError = (error) => {
        setLoading(false);
      }

      // Wait until stats have been loaded to populate selected stats
      if (editEnabled === true) {
        playerSlimSummaryRequest(handleSummaryResponse,handleSummaryError);

        // Disable edit mode once stats have been populated
        // To be honest, this is bad design but I couldn't get it to work any other way because
        // they share the same page, so location wasn't triggering re-renders
        setEditEnabled(false);
      } else {
        setRowSelection({});
      }

      setLoading(false);

    }
    const handleStatisticsError = (error) => {
      // Only stop loading if not going to continue with processing
      if (editEnabled === false) {
        setLoading(false);
      }
    }

    
  
    useEffect(() => {
      setLoading(true);
  
      const params = {
        league: league
      }
      
      playerStatisticsRequest(params,handleStatisticsResponse,handleStatisticsError)
      
  }, [league]);
  
    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const columns = useMemo(
      () => [
        {
          accessorKey: "statistic",
          header: "Statistic",
          size: isMobile ? 1 : 200,
        },
        { // Combine as string for filtering/searching
          // but actual display is defined by Cell parameter.
          // Need to use row.orignal rather than Cell or renderedCellValue.
          accessorFn: (row) => row.category.join(','), 
          id: 'category',
          header: 'Category',
          filterVariant: 'multi-select',
          filterSelectOptions: categories,
          Cell: ({ row }) =>
            <CategoriesStack row={row} isMobile={isMobile} />
        }
      ],
      [categories,isMobile]
    );
  
    ////////////////////////////////////////////////////////
    // Run button handler function
    ////////////////////////////////////////////////////////
  
    const navigate = useNavigate();
  
    const handleTrainResponse = (response) => {
      setIsTraining(false);
      navigate('/model-summary/player');
    }
    const handleTrainError = (error) => {
      if (error.response) {
        if (error.response.data.csrf) {
          setUserPopupOpen(true);
        } else if (error.response.status == 401 ) {
          // Unauthorized
          setPricingPopupOpen(true);
        }
        else {
          // Something went wrong
          setSomethingWentWrong(true);
        }

        // Always stop loading
        setIsTraining(false);
      }
    }
  
    const handleRunButtonClicked = () => {
      setIsTraining(true); // Set training state to true
      const requestBody = JSON.stringify({
        selectedStats: Object.keys(rowSelection),
        hyperparameters: hyperparameters
      });
      const params = {
        league: league,
        position: position,
        prop: prop,
        custom_hyperparameters_enabled: customHyperparametersEnabled
      }
      playerTrainRequest(requestBody,params,handleTrainResponse,handleTrainError);
      };
  
    ////////////////////////////////////////////////////////
    // Updating stats based on position
    ////////////////////////////////////////////////////////
    
    const handlePositionDropdownChanged = (event) => {
      const localPosition = event.target.value;
      const localAvailableProps = positionLabelsDataAllPositions[localPosition];
  
  
      // Update position of table
      setPosition(localPosition);
  
      // Update stats data being used by table based on selected position
      setStatsData(statsDataAllPositions[localPosition]);
  
      // Update categories being used by table based on selected position
      setCategories(categoriesAllPositions[localPosition]);
  
      // Order matters here
      setProp(localAvailableProps[0]);
      setAvailableProps(localAvailableProps);
  
      // Clear selected stats
      setRowSelection({});
    }

    // Styling definitions
    const gridPadding = {xs:0.2,sm: 1};
  
    return (
      <>
        <Grid container rowSpacing={3} direction={largeScreen ? "row" : "column"} justifyContent="center" sx={{mb: 4}}>
          <Grid item xs={4} sx={{ p: gridPadding}}>
            <SelectedStatsContainer rowSelection={rowSelection} setRowSelection={setRowSelection} isTraining={isTraining} />
          </Grid>

          {!largeScreen &&
            <Grid container item direction="row" spacing={1} justifyContent="center" className='onboarding-step-1'>
              <PositionPropDropdowns
                position={position}
                handlePositionDropdownChanged={handlePositionDropdownChanged}
                availablePositions={availablePositions}
                prop={prop}
                setProp={setProp}
                availableProps={availableProps}
                loading={loading}
              />
            </Grid>
          }
          
          <Grid item xs={8} justifyContent="center" sx={{ p: gridPadding}}>
            {/* Stat Selection Table */}
            <Box component='div' className='onboarding-step-2'>
              <StatSelectionMRT
                columns={columns}
                data={statsData}
  
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
  
                loading={loading}
                height={tableHeight}
  
                isMobile={isMobile}
                theme={theme}
  
                getRowId= {(row) => row.statistic}
  
                mrtTheme={isMobile && {
                    baseBackgroundColor: theme.palette.background.default
                }}
  
                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {
                  return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <Grid container direction="row" spacing={1} className='onboarding-step-1' 
                      sx={{
                        ...(isMobile && {
                          width: 50
                        })
                      }}
                      >
                        <Grid item>
                          <Button
                          color={theme.primary}
                          variant="contained"
                          onClick={handleRunButtonClicked}
                          disabled = {Object.keys(rowSelection).length === 0}
                          className="onboarding-step-5"
                          sx={{width: '100%',height:'100%'}}
                          >
                          Run
                          </Button>
                        </Grid>
                        {largeScreen &&
                          <PositionPropDropdowns
                            position={position}
                            handlePositionDropdownChanged={handlePositionDropdownChanged}
                            availablePositions={availablePositions}
                            prop={prop}
                            setProp={setProp}
                            availableProps={availableProps}
                            loading={loading}
                          />
                        }
                      </Grid>
                    </div>
                  );
                }}
                />
            </Box>
          </Grid>
          

            <Grid container>
              <Grid item xs={12} sx={{p: gridPadding}} className="onboarding-step-4">
                <HyperParametersContent 
                league={league}
                modelType={"player"}
                enabled={customHyperparametersEnabled}
                setEnabled={setCustomHyperparametersEnabled}
                hyperparameters={hyperparameters}
                setHyperparameters={setHyperparameters}
                dataFetched={hyperparametersFetched}
                setDataFetched={setHyperparametersFetched}
                setUserPopupOpen={setUserPopupOpen}
                setSomethingWentWrong={setSomethingWentWrong}
                position={position}
                />
              </Grid>
            </Grid>
          </Grid>

        <SomethingWentWrongPopup open={somethingWentWrong} setOpen={setSomethingWentWrong} />
        
        {/* This popup will only try to train a model and are not logged in*/}
        <UserPopup open={userPopupOpen} setOpen={setUserPopupOpen} />
  
        {/* This popup will only get rendered if try to try a model, we are logged in and are unauthorized */}
        <PaymentOptionsPopup open={pricingPopupOpen} setOpen={setPricingPopupOpen} isLoggedIn={true} unauthorized={true}/>
        </>
    );
  }