
import {
    Chip,
    Stack,
    Typography
} from '@mui/material';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { useTheme } from '@mui/material/styles';

export function ErrorDisplay({error}){

    const theme = useTheme();
    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center"
                sx={{border: 2, borderRadius: 1, borderColor: theme.palette.error.main, p: 1}}>
                <HighlightOffOutlinedIcon fontSize="small" color="error" />
                <Typography variant="body" color="error">
                    {error}
                </Typography>
            </Stack>
        </>
    )
}

export function SuccessDisplay({message,Icon}){

    const theme = useTheme();
    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center"
                sx={{border: 2, borderRadius: 1, borderColor: theme.palette.success.main, p: 1}}>
                <Icon fontSize="small" color="success" />
                <Typography variant="body">
                    {message}
                </Typography>
            </Stack>
        </>
    )
}

export function StatCount({count}){

    return (
        <Chip label={count} size="small" sx={{minWidth: 50}}/>
    )
}