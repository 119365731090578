
import { ResponsiveContainer } from '../../../components/responsive';
import { Changelog } from '../components/changelog';
import { SeoHelmet } from '../../../components/seo';


export default function ChangelogPage(){
    return (
        <>
            <SeoHelmet 
            title="Changelog"
            description="Check out our recent updates"
            />
            <ResponsiveContainer>
                <Changelog />
            </ResponsiveContainer>
        </>
    );
}