
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from './error-boundary';

import AuthProvider from '../hooks/auth';

import themeDark from '../style/theme';
import RouteProvider from './routes';

function App() {
  // Initialize Google Analytics Tag
  ReactGA.initialize(
      'G-8XB4XKKWWW', // GA Measurement ID
      {
        testMode: process.env.REACT_APP_REACT_GA_MODE === 'TEST'
      }
  );

  const helmetContext = {};

  return (
    <>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <AuthProvider>
        <ThemeProvider theme = {themeDark}>
          <CssBaseline />
            <HelmetProvider context={helmetContext}>
              <ErrorBoundary>
                <RouteProvider />
              </ErrorBoundary>
            </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
    </>
  );
}

export default App;
