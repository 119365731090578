import React, { useState } from 'react';
import {
    Box,
    Stack,
    Typography
} from '@mui/material/';
import { ResponsiveContainer } from '../../../components/responsive';
import { SeoHelmet } from '../../../components/seo';

export default function PrivacyPolicy() {
    return (
        <>
        <SeoHelmet 
        title="Privacy Policy"
        description="All users must comply with the privacy policy"
        />
        <ResponsiveContainer>
            <Box sx={{ display: 'flex', top: 10 }}>
                <Stack direction="column" width="50%" alignItems="left" justifyContent="space-between">
                    <h1>Solved Sports Privacy Policy</h1>
                    <h2>Introduction</h2>
                    <Typography component="section">
                        The Solved Sports LLC Privacy Policy discusses and governs the collection and use of any
                        information that is submitted to, or collected by, Solved Sports LLC. This Privacy Policy applies
                        to the Solved Sports LLC website (the “Website”), owned and operated by Solved Sports LLC
                        (“the Company,” “we,” “us,” or “our”). This Privacy Policy does not address the privacy
                        practices of any third parties that we do not own, control, or are affiliated with. By visiting
                        and/or using our Website and Services, you agree to the terms of this Privacy Policy and the
                        accompanying Terms. Please read the Solved Sports LLC Privacy Policy to help you make
                        informed decisions.
                    </Typography>
                    <h2>Information We Collect</h2>
                    <Typography component="section">
                        In the course of operating the Website and providing our Services, we will collect and/or receive
                        the following types of information. You authorize us to collect and/or receive such information.
                        <ul>
                            <li>Personal Information: We only receive or collect information that identifies you personally if you 
                                choose to provide such personally identifiable information to us via
                                email or other means. When you sign up to become a user or contact us, you will be
                                required to provide us with personal information about yourself (collectively, the
                                “Personal Information”). Such Personal Information may include your name, e-mail
                                address, username, and password. We do not collect any Personal Information from you
                                when you use the Website unless you provide us with the Personal Information
                                voluntarily.</li>
                            <li>Payment Information: If you choose to make a purchase or subscribe to a feature or
                                service that requires a fee, you will be required to provide us with your payment
                                information, including, without limitation, bank account numbers, credit card or debit
                                card numbers, account details, ACH information, and similar data (collectively,
                                “Payment Information”). Such Payment Information will be collected and processed by
                                our third-party payment vendors pursuant to the terms and conditions of their privacy
                                policies and terms of use, and we do not obtain access to any Payment Information in
                                connection with such purchases or subscriptions</li>
                            <li>Other Information: In addition to Personal Information and Payment Information, we
                                may automatically collect or receive additional information regarding you and your use
                                of the Website; your interactions with us and our advertising; and information regarding
                                your computer and mobile devices used to access the Website (collectively, the “Other
                                Information”).</li>
                        </ul>
                    </Typography>
                    <h2>Cookies</h2>
                    <Typography component="section">
                        Our Website makes use of cookies which are small digital files that are stored in your web
                        browser that enable us to track your return visits to our website. Your mobile device settings may
                        allow you to block these cookies, but we recommend you have them enabled to help us personalize your experience on our website. Additionally, third party advertisers on our site may
                        use cookies for tracking purposes.
                    </Typography>
                    <h2>Information We Retain</h2>
                    <Typography component="section">
                        Your information will only be retained as long as reasonably necessary for the purposes set out in
                        this privacy policy, and in accordance with applicable law.
                    </Typography>
                    <h2>Changes to the Privacy Policy</h2>
                    <Typography component="section">
                        This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. Solved Sports
                        LLC retains the right to update this Privacy Policy whenever we deem fit. Please revisit our Privacy
                        Policy occasionally to review any changes. If we make material changes to the Privacy Policy, we
                        will provide appropriate notice to you. Your continued use of the Services means that you accept
                        these changes.
                    </Typography>
                    <h2>CA Residents</h2>
                    <Typography component="section">
                        If you have questions or concerns about this Privacy Policy, please contact us
                        at <a href="info@solvedsports.com">info@solvedsports.com</a>.
                        <br />
                        <br />
                        Please provide sufficient information that allows us to verify whether you are the person about
                        whom we collected personal information or an authorized representative. We endeavor to respond
                        to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90
                        days), we will inform you of the reason and extension period in writing.
                    </Typography>
                    <Typography component="section">
                        If you are a resident of California, you are entitled to certain rights with respect to your personal
                        information under the California Consumer Privacy Act (California Civil Code §§1798.100 -
                        1798.199) (the “CCPA”).
                        <br />
                        <br />
                        <u>Your Rights Under the CCPA</u>
                        <br />
                        <br />
                        Right to Know: You have the right to request that we disclose what personal information we
                        collect, use, disclose, and sell. This includes the categories or personal information collected, the
                        categories of sources from which the information is collected, the business or commercial purpose
                        for collecting or selling the information, and the categories of third parties with whom we share
                        the information.
                        <br />
                        <br />
                        Right to Delete: You have the right to request the deletion of your personal information that we
                        have collected, subject to certain exceptions.
                        <br />
                        <br />
                        Right to Opt-Out: You have the right to opt-out of the sale of your personal information.
                        <br />
                        <br />
                        Right to Non-Discrimination: You have the right to not receive discriminatory treatment by us for
                        exercising your rights under the CCPA. This means we will not deny you goods or services, charge
                        you different prices or rates, or provide a different level or quality of goods or services unless the
                        difference is reasonably related to the value of your personal information.
                        <br />
                        <br />
                        <u>Exercising Your CCPA Rights</u>
                        <br />
                        <br />
                        To exercise any of your CCPA rights, please contact us at the contact information provided at the
                        end of this Privacy Policy.
                    </Typography>
                    <h2>Contact Us</h2>
                    <Typography component="section">
                        If you have questions or concerns about this Privacy Policy, please contact us
                        at <a href="info@solvedsports.com">info@solvedsports.com</a>.
                        <br />
                        <br />
                        Please provide sufficient information that allows us to verify whether you are the person about
                        whom we collected personal information or an authorized representative. We endeavor to respond
                        to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90
                        days), we will inform you of the reason and extension period in writing.
                    </Typography>
                </Stack>
            </Box>
        </ResponsiveContainer>
        </>
    );
}
