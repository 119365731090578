
import {
    Divider,
    Stack,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import '../../../style/blog.css';

export function ArticleRender({article}){

    return (
        <>
            <Typography variant="h4">{article.title}</Typography>
            <Stack direction="column" spacing={1} sx={{mt: 1}}>
                <Typography variant="subtitle">
                    By {article.author} on {dayjs(article.date_updated ? article.date_updated : article.date_created).format('MMM Do, YYYY')}
                </Typography>
                <Divider />
            </Stack>
            
            
            
            <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </>
    )
}