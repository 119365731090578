import { useLocation } from 'react-router-dom';

import ProfileDashboard from '../components/dashboard';
import { SeoHelmet } from '../../../components/seo';

export default function Profile() {
  let location = useLocation();
  const selectedTab = location.state !== null ? location.state.selectedTab : 'Account';

  return (
    <>
      <SeoHelmet 
      title="Profile"
      description="View your saved betting models. Update account or subscription."
      />
      <ProfileDashboard selectedTab={selectedTab} />
    </>
  )
};