import { useParams } from 'react-router-dom';

import { ResponsiveContainer } from '../../../components/responsive';
import { ModelSummaryDashboard } from '../components/dashboard';
import { ModelSummaryFAQs } from '../../../components/onboarding/faqs';
import { SeoHelmet } from '../../../components/seo';

export default function ModelSummary(){
    const { target } = useParams();

    return (
        <>
            <SeoHelmet 
            title="Model Summary"
            description="View the performance of your model. Evaluate historical profitability and machine learning success."
            />
            <ResponsiveContainer>
                <ModelSummaryDashboard modelType={target} />
                <ModelSummaryFAQs sx={{mt: 4}}/>
            </ResponsiveContainer>
        </>
    );
}

