import React from 'react';
import { ResponsiveContainer } from '../../../components/responsive'
import {
    Box,
    Stack,
    Typography
} from '@mui/material/';
import { SeoHelmet } from '../../../components/seo';


export default function PrivacyPolicy() {
    return (
        <>
        <SeoHelmet 
        title="Terms of Use"
        description="All users must comply with the Terms of Use"
        />
        <ResponsiveContainer>
            <Box sx={{ display: 'flex' }}>
                <Stack direction="column" width="50%" alignItems="left" justifyContent="space-between">
                    <h1>Solved Sports Terms of Use</h1>
                    <h2>Introduction</h2>
                    <Typography component="section" sx={{ marginTop: 2 }}>
                        <strong>Last Revised: July 9, 2024</strong>
                        <br />
                        <br />
                        Please read this agreement carefully.
                        <br />
                        <br />
                        The following Terms and Conditions of Use constitute a binding agreement between you (“User”)
                        and Solved Sports LLC (the “Company,” “Solved Sports LLC,” “we”, “us”, or “our”). By
                        accessing or using Solved Sports LLC’s website, mobile application, or any other platforms
                        associated with Solved Sports LLC, you agree to be bound by Solved Sports LLC’s Terms and
                        Conditions of Use (the “Terms” or the “Agreement”), including Solved Sports LLC’s Privacy
                        Policy.
                        <br />
                        <br />
                        By accessing <a href='www.solvedsports.com'>www.solvedsports.com</a> (the “Website”) or any other platforms or services Solved
                        Sports LLC may offer (collectively, the “Service” or our “Services”), you agree to, and are bound
                        by this Agreement. This Agreement applies to anyone who accesses the Website or uses our
                        Services, regardless of registration or subscription status.
                    </Typography>
                    
                    <h2>Modification</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        These Terms constitute the entire agreement between you and Solved Sports LLC regarding your
                        use of our Website and Services and supersede all prior written and oral agreements.
                    </Typography>
                    
                    <h2>Privacy Policy</h2>
                    
                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Solved Sports LLC’s Privacy Policy discusses how we collect, process, and disclose personal
                        information. Please read this policy carefully.
                    </Typography>
                    
                    <h2>Account Eligibility</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Users are not authorized to create an account with Solved Sports LLC and use Solved Sports LLC’s
                        services unless all of the following are true: (1) You are at least 18 years old OR if you are under
                        the age of 18, that you obtained a parent or legal guardian’s consent before accessing the Website
                        and/or using our Services; (2) you are qualified to enter a binding contract with the Company; (3)
                        you are not prohibited by law from using our Services; (4) you do not have more than one account
                        on our Services; and (5) you have not previously been removed from our Services.
                        <br />
                        <br />
                        By using Solved Sports LLC’s Services, you represent and warrant that all of the above are true.
                        If at any time you cease to meet these requirements, all authorization to access our Website and
                        Services is automatically revoked, and you must immediately delete your account, and we retain
                        the right to remove your access to our services without warning.
                    </Typography>
                    
                    <h2>Subscriptions</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        The Services provided are offered on a subscription basis (“Subscription(s)”). To process the
                        payment for your Subscription, a valid payment method is required. You must provide Solved
                        Sports LLC, or any third-party payment processor, with complete and accurate payment
                        information (“Payment Information”). How Solved Sports LLC will use this Payment Information
                        is explained in the Privacy Policy. Please read the Privacy Policy carefully. By submitting this
                        Payment Information, you authorize Solved Sports LLC and our third-party payment processor to
                        charge all Subscription fees incurred on your account to the payment method you have provided.
                        <br />
                        <br />
                        The frequency of Payment will be determined by the type of subscription plan you select when
                        purchasing a Subscription - either monthly or annually. Your subscription will automatically renew
                        at the end of each payment cycle under the exact same conditions unless you cancel your
                        Subscription or Solved Sports LLC cancels your subscription.
                        <br />
                        <br />
                        If automatic payment fails for any reason, Solved Sports LLC will issue an electronic invoice
                        indicating that you must manually proceed with the full payment corresponding to the billing
                        period to avoid cancellation of your Subscription.
                        <h5>Fee Changes and Refunds</h5>
                        Solved Sports LLC reserves the right to modify our Subscription fees at any time for any reason.
                        Any changes to the Subscription fee will be effective at the end of the current payment cycle. Prior
                        notice of changes to Subscription fees will be sent to the email address you have provided to Solved
                        Sports LLC. Your continued use of the Service after a change in Subscription fee means you accept
                        the updated Subscription fee.
                        <br />
                        <br />
                        We do not guarantee refunds for Subscriptions. Any refunds granted will be given at the discretion
                        of Solved Sports LLC.
                    </Typography>
                    
                    <h2>Content</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        During your use of our Website and Services, you'll encounter: (i) “Your Content,” which refers
                        to the content you upload or provide; (ii) “User Content,” referring to content uploaded or provided
                        by other users. Within this agreement, "content" encompasses, but is not limited to, text, images,
                        video, audio, or any other material found on our Services, including information within users'
                        profiles and messages posted on the Website or exchanged between users.
                        <h5>Your Content</h5>
                        You bear sole responsibility and liability for Your Content, and you agree to indemnify, defend,
                        release, and absolve Solved Sports LLC from any claims arising from Your Content. You
                        understand and acknowledge our right to monitor or review Your Content, and we reserve the right
                        to remove, edit, or restrict access to Your Content at our discretion. You understand and
                        acknowledge that we have no obligation to display or review Your Content.
                        The User agrees and represents that any uploaded content is created, owned, or licensed for use by
                        the user. Users may not upload content that contains third-party copyrighted material, or material
                        that is subject to other third-party rights, without permission from the rightful owner of the
                        material.
                        <br />
                        <br />
                        You acknowledge that any materials uploaded to the Website may be subject to posted limits on
                        use, reproduction, and/or dissemination, and you are responsible for abiding by such limitations
                        with respect to your submissions.
                        <br />
                        <br />
                        Solved Sports LLC claims no ownership, does not assume any liability, and does not endorse nor
                        guarantee the accuracy or propriety of any of the information its users submit through the Website.
                        <br />
                        <br />
                        The User acknowledges that Solved Sports LLC has no obligation to pre-screen or monitor
                        materials uploaded through the Website.
                        <h5>User Content</h5>
                        Other Solved Sports LLC Users share their own content on our Services. User Content is the
                        property of the user who posted it and is stored on our servers and displayed at their direction. You
                        don't possess any rights regarding User Content, and unless Solved Sports LLC explicitly
                        authorizes it, you can only use User Content within the scope of our Services' purpose, which is to
                        facilitate communication and connection among users. Using User Content for commercial gain,
                        spamming, harassing, or making unlawful threats is strictly prohibited. Misuse of User Content
                        may result in the termination of your account.
                    </Typography>
                    
                    <h2>Links to Other Websites</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        We understand that our users may find it helpful to access third-party websites or services through
                        our Services, including through sportsbook affiliate links. Solved Sports LLC does not own or
                        control these third-party websites or services, and we are not responsible for their content, privacy
                        policies, or practices. We do not endorse or guarantee the offerings of any third-party entities or
                        individuals, or their websites. Solved Sports LLC does not engage in sports betting or take bets of
                        any kind. By using our Services, you acknowledge and agree that we shall not be responsible or
                        liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
                        connection with the use of or reliance on any content, goods, or services available through any
                        such third-party websites or services. We strongly advise you to review the terms and conditions
                        and privacy policies of any third-party websites or services that you visit. Your use of these
                        websites or services is at your own risk, and we cannot guarantee their security or reliability.
                    </Typography>
                    
                    <h2>Intellectual Property and Limited License</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Solved Sports LLC, and its partners and/or licensors, own the Website and all Content provided
                        (other than Your Content and User Content) (“Site Content”) and all intellectual property rights
                        relating to the Site and Site Content worldwide. All Site Content included as part of the Service,
                        such as trademarks, text, graphics, images, software, and any compilation thereof is the sole property of their respective owners. Solved Sports LLC’s name and logo, and all related names,
                        logos, product and service names, designs, and slogans are trademarks of Solved Sports LLC, its
                        affiliates, or licensors.
                        <br />
                        <br />
                        You do not acquire any ownership rights in any content. Solved Sports LLC does not grant you
                        any licenses, express or implied, to the intellectual property of Solved Sports LLC or our licensors
                        except as expressly authorized by these Terms.
                        <br />
                        <br />
                        Subject to you complying with these Terms, you are granted a limited, non-transferable, non-
                        sublicensable, non-exclusive, revocable license to access the Solved Sports LLC’s proprietary
                        software (the “Software”). You are granted this license to access the Software, and Solved Sports
                        LLC enables you to access and use the Software, subject to the other applicable provisions,
                        limitations and restrictions in these Terms.
                        <br />
                        <br />
                        You may not upload or republish the Software or software content on any Internet, intranet or
                        extranet sites or incorporate the information in any other database or compilation in any monetized
                        post or upload or republish the Software or software content in any manner for which you receive
                        compensation or that violates any other provision of these Terms.
                        <br />
                        <br />
                        You acknowledge and agree that all content, design elements, and materials available on the
                        Website, including, without limitation, the editorial coding and metadata contained therein,
                        (“Solved Sports LLC Content”) are owned by Solved Sports LLC and/or its partners and licensors
                        and protected by copyrights, trademarks, trade secrets, and/or other proprietary rights of Solved
                        Sports LLC and/or its partners and licensors. Solved Sports LLC and its partners and licensors
                        retain all right, title and interest in and to the Solved Sports LLC Content. You may not use, copy,
                        reproduce, modify, republish, upload, post, transmit or distribute any Solved Sports LLC Content
                        in any form or for any means, unless expressly permitted in these Terms.
                        Solved Sports LLC’s trademarks and trade dress may not be used, including as part of trademarks
                        and/or as part of domain names, in connection with any product or service in any manner that is
                        likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the
                        prior written permission of Solved Sports LLC.
                        <br />
                        <br />
                        You may not sell, license, rent, perform, display, create derivative works from, or in any way use
                        or exploit Solved Sports LLC Content in any way unless expressly permitted in this Agreement.
                        You agree not to: (a) disassemble, decompile or reverse engineer any software or other component
                        of the Software or Solved Sports LLC Content; (b) examine the Software with debugging, memory
                        inspection, or disk inspection tools; (c) rent or sublicense the Software; (d) permit use of the
                        Software by a person who is not a registered User; (e) transmit an electronic copy of the Software
                        by any means; or (f) use the Software in the operation of a service bureau or time sharing
                        arrangement, or to provide outsourcing services. To the extent any such disassembly,
                        decomplication or reverse engineering is applicable under the law for error correction or otherwise,
                        you agree that you shall submit a written request to engage in such activity for a legally recognized
                        purpose and Solved Sports LLC shall have a reasonable period of time (not to exceed 30 calendar days) to rectify such issue prior to any such disassembly, decompilation or reverse engineering
                        which shall be for the sole purpose and duration of effectuating any required error correction.
                        <br />
                        <br />
                        Unless explicitly stated herein, nothing in these Terms shall be construed as conferring to you any
                        license to Solved Sports LLC Content. You agree not to assert against Solved Sports LLC any
                        claim, action or proceeding for infringement of any intellectual property rights.
                        You hereby grant Solved Sports LLC a non-exclusive, royalty-free, and non-transferable license
                        to use, copy, store, modify, and display Personal Data as necessary to provide the Service in
                        accordance with this Agreement. Personal Data submitted by User to the Service, remains the sole
                        property of the User and User reserves all right, title, and interest in the Personal Data.
                        Notwithstanding any other provision in this Agreement, Solved Sports LLC may collect and
                        provide certain User registration and statistical information, such as usage or User traffic patterns,
                        in aggregate and anonymized form to third parties, provided that such information does not identify
                        any User and contains no personally identifying information. Solved Sports LLC may access
                        User’s accounts, including, without limitation, Personal Data, to the extent necessary to respond
                        to service or technical problems.
                        <br />
                        <br />
                        You hereby grant Solved Sports LLC a non-exclusive, non-transferable, and royalty-free license
                        to use User Marks for the limited purposes set forth in this Agreement, including as necessary for
                        <br />
                        <br />
                        Solved Sports LLC’s performance under this Agreement, to deliver the Service in accordance with
                        this Agreement, and in marketing materials related to the Service.
                        <br />
                        <br />
                        Solved Sports LLC will have the right to use, act upon, and freely exploit any suggestion, idea,
                        enhancement request, feedback, recommendation, or other information provided by User or any
                        third party acting on User’s behalf, without any remuneration, fee, royalty, or expense of any kind,
                        and Solved Sports LLC will hereby own all rights, title, and interest in any such suggestion, idea,
                        enhancement request, feedback, recommendation, or other information provided by User or any
                        third party acting on User’s behalf.
                    </Typography>

                    <h2>Copyright Policy</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Solved Sports LLC respects the intellectual property rights of others and expects Users to do the
                        same. Solved Sports LLC users are prohibited from uploading any content that they do not have
                        the right to post, including without limitation copyrighted material. Solved Sports LLC will
                        cooperate and respond to notices of alleged copyright infringement that have a reasonable legal
                        basis for the claim, as determined by legal counsel for Solved Sports LLC. Any infringing material
                        discovered on the Website will be removed from the Website. Repeat infringers will not be
                        tolerated.
                        <br />
                        <br />
                        Solved Sports LLC is not liable for the infringement, copyright or otherwise, of its Users or any
                        Third Parties.
                        <br />
                        <br />
                        If you believe that your work has been copied in a way that constitutes copyright infringement,
                        please provide Solved Sports LLC all of the written information specified below. Please note that
                        this procedure is exclusively for notifying Solved Sports LLC that your copyrighted material has
                        been infringed. Please include the following:
                        <ol>
                            <li>An electronic or physical signature of the person authorized to act on behalf of the owner
                                of the copyright interest;</li>
                            <li>A description of the copyrighted work that you claim has been infringed;</li>
                            <li>A description of where the material that you claim is infringing is located on the Site,
                                including the website address/URL;</li>
                            <li>Your address, telephone number, and e-mail address;</li>
                            <li>A statement that you have a good-faith belief that the disputed use is unauthorized by the
                                copyright owner, its agent, and/or the law;</li>
                            <li>A statement by you, made under the penalty of perjury, that the above information in your
                                notice is accurate and that you are the copyright owner or authorized to act on the copyright
                                owner’s behalf.</li>
                        </ol>
                        Any copyright complaints can be submitted to: <a href='legal@solvedsports.com'>legal@solvedsports.com</a>.
                    </Typography>
                    
                    <h2>Indemnification</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        To the fullest extent allowed by applicable law, you agree to indemnify and hold Solved Sports
                        LLC, its affiliates, officers, agents, employees, and partners harmless from and against any and all
                        claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’
                        fees) arising from or in any way related to any third party claims relating to (a) your use of our
                        Website and Services (including any actions taken by a third party using your account), and (b)
                        your violation of these Terms.
                    </Typography>
                    
                    <h2>Terminating and Monitoring</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        These Terms will continue to apply until terminated by the User or Solved Sports LLC. Any User
                        who wishes to withdraw their acceptance of these Terms and/or delete their account may do so by
                        notifying Solved Sports LLC via email at help@solvedsports.com. Solved Sports LLC, in its sole
                        discretion, may terminate access to the Services if the User has violated any provision of these
                        Terms, the Privacy Policy, or any other policy provided to users by Solved Sports LLC governing
                        the use of the Website or the Services; (ii) if Solved Sports LLC is required to do so by law; or
                        (iii) if Solved Sports LLC elects to no longer provide the Website or Services.
                        <br />
                        <br />
                        Further, Solved Sports LLC has the right to:
                        <ul>
                            <li>Remove any user account for any reason in our sole discretion;</li>
                            <li>Take appropriate legal action, including without limitation referral to law enforcement, for
                                any illegal or unauthorized use of Solved Sports LLC’s Website or Services; and</li>
                            <li>Terminate or suspend your access to all or part of our Services for any reason, including
                                violations of these Terms, violation of any applicable laws or to protect the safety or
                                property of other users, Solved Sports LLC, or third parties.</li>
                        </ul>
                        Upon receipt of a User’s request to delete their account, and except as set forth below, Solved
                        Sports LLC will remove the account and the associated information within a reasonable amount
                        of time. Please note that any information you have submitted to publicly accessible areas may not
                        be removable.
                    </Typography>
                    
                    <h2>Warranties</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        THE USER EXPRESSLY UNDERSTANDS AND AGREES THAT USE OF THE WEBSITE
                        AND SERVICES, OR CONTENT DOWNLOADED OR OTHERWISE OBTAINED
                        THROUGH THE USE OF IT, IS AT THE USER’S SOLE RISK AND ARE PROVIDED ON AN
                        “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
                        EXPRESSED OR IMPLIED; INCLUDING ANY IMPLIED WARRANTIES, TERMS AND
                        CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                        PARTICULAR PURPOSE, AND NON-INFRINGEMENT, WITH RESPECT TO ANY
                        SERVICES OR CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                        WEBSITE, AND THE WEBSITE ITSELF, TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW.
                        <br />
                        <br />
                        USE OF THE WEBSITE, SERVICES, OR CONTENT DOWNLOADED, OR OTHERWISE
                        OBTAINED THROUGH THE USE OF IT, IS AT THE USER’S OWN DISCRETION AND
                        RISK, AND THE USER IS SOLELY RESPONSIBLE FOR ANY AND ALL DAMAGE TO THE
                        USER’S COMPUTER OR ELECTRONIC DEVICE(S), OR LOSS OF DATA THAT RESULTS
                        FROM SUCH USE.
                    </Typography>
                    
                    <h2>Limitation of Liability</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        THE USER EXPRESSLY UNDERSTANDS AND AGREES THAT SOLVED SPORTS LLC
                        AND ITS SUBSIDIARIES AND AFFILIATES SHALL NOT BE LIABLE TO THE USER
                        UNDER ANY THEORY OF LIABILITY (WHETHER CONTRACT, OR TORT, INCLUDING
                        NEGLIGENCE OR OTHERWISE) FOR ANY DAMAGES, INCLUDING LOSS OF INCOME,
                        BUSINESS, OR PROFITS THAT MAY BE INCURRED BY THE USER THROUGH USE OF
                        THE SERVICE, ANY SERVICES OR CONTENT DOWNLOADED, OR OTHERWISE
                        OBTAINED FROM THE SERVICE, INCLUDING ANY LOSS OF DATA. IN ANY EVENT,
                        SOLVED SPORTS LLC’S LIABILITY SHALL NEVER EXCEED ONE HUNDRED USD
                        ($100.00).
                    </Typography>
                    
                    <h2>Miscellaneous</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Solved Sports LLC may assign, transfer, sell, or otherwise dispose of its rights and obligations
                        under the Terms, in whole or in part, at any time, without notice. Users may not assign this
                        agreement, or any part of it to another party or individual.
                    </Typography>
                    
                    <h2>Notice</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        Solved Sports LLC may notify users with respect to the Website by sending an e-mail message to
                        the e-mail address provided by the user or by a posting on the Website. Notices are effective
                        immediately, whether or not the user receives actual notice of the changes.
                    </Typography>
                    
                    <h2>Governing Law</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        To the maximum extent permitted by law, this agreement is governed by the laws of the
                        Commonwealth of Massachusetts, and you hereby consent to the exclusive jurisdiction and venue
                        of courts in Massachusetts in all disputes arising out of or relating to the use of the Website. Use
                        of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these
                        Terms, including, without limitation, this section.
                    </Typography>
                    
                    <h2>No Waiver</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        No waiver by Solved Sports LLC of any term or condition set out in these Terms shall be deemed
                        a further or continuing waiver of such term or condition or a waiver of any other term or condition,
                        and any failure of our Terms to assert a right or provision shall not constitute a waiver of such a
                        right or provision.
                    </Typography>
                    
                    <h2>Assignment</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        You may not assign or transfer these Terms, by operation of law or otherwise, without Solved
                        Sports LLC’s prior written consent. Any attempt by you to assign or transfer these Terms without
                        such consent will be null and of no effect. Solved Sports LLC may assign these Terms, at its sole
                        discretion, without restriction. Subject to the foregoing, these Terms will bind and inure to the
                        benefits of the parties, their successors and permitted assignees. These Terms do not and are not
                        intended to confer any rights or remedies upon any person other than the parties.
                    </Typography>
                    
                    <h2>Entire Agreement</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        These Terms constitute the entire agreement between you and Solved Sports LLC regarding your
                        use of our Website and Services and supersede all prior written and oral agreements.
                    </Typography>
                    
                    <h2>Contact Us</h2>

                    <Typography component="section" sx={{ marginTop: 2 }}>
                        In order to resolve a complaint regarding Solved Sports LLC or to receive further information
                        regarding use of our Website or Services, please contact us at <a href='info@solvedsports.com'>info@solvedsports.com</a>.
                    </Typography>
                </Stack>
            </Box>
        </ResponsiveContainer>
        </>
    );
}
