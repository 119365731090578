
import {useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';

import dayjs from 'dayjs';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';

import { 
    TotalBlockDisplay,SpreadBlockDisplay, 
    BestOddsTotalBlockDisplay, 
    BestOddsSpreadBlockDisplay 
} from '../common/prediction-displays';

import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    validMarkets,
    validSportsbooks 
} from '../../../../components/table';

import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ExportPredictionsButton } from '../common/export-predictions';

function MarketSelectionDropdown({marketSelection,onChangeFn,width=null}){

    return (
        <SingleSelect 
            label="Market"
            value={marketSelection}
            availableValues={validMarkets.map(value => value.key)}
            availableValuesDisplay={validMarkets.map(value => value.display)}
            onChangeFn={onChangeFn}
            width={width}
        />
    )
}

function TeamPredictionResultsFilter(
    {marketSelection,sportsbookSelection,setSportsbookSelection,
    largeScreen,handleColumnVisibilityToggle,
    predictionData,setFilteredPredictionsData}
){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Market and Sportsbook filters are tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => predicted_odds_values[marketSelection].max_value > 0 || !showValueBetsOnly;

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = predictionData.filter(valueFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[marketSelection,showValueBetsOnly]);
     
    return (
        <>
            <FilterMenu>
                {/* The below items are only shown on small screens */}
                {!largeScreen &&
                <MenuItem>
                    <MarketSelectionDropdown marketSelection={marketSelection} onChangeFn={handleColumnVisibilityToggle} />
                </MenuItem>
                }
                {!largeScreen &&
                <MenuItem>
                    <SingleSelect 
                    label="Sportsbook"
                    value={sportsbookSelection}
                    availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                    availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                    onChangeFn={(event) => {setSportsbookSelection(event.target.value)}}
                    />
                </MenuItem>
                }

                {/* The below items are always shown in the filter */}
                <MenuItem>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showValueBetsOnly}
                            onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        }
                    label="Show value bets only"
                    />
                </MenuItem>
            </FilterMenu>
        </>
    )
}

function TeamPredictionResultsSortBy({filteredData,setFilteredData,marketSelection}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }


        // If columns change, we need to reapply sorting
        useEffect(() => {
            if (selectedIndex !== null) {
                sortData(options[selectedIndex].sortFn);
            }
         },[marketSelection]);

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}



export function TeamPredictionResultsTable({league,date,predictionData,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const columnVisibilityDict = {
        "spreads" : {
            "predicted_spreads" : true,
            "best_odds_spreads"  : true,
            "draftkings_spreads" : true,
            "fanduel_spreads"   : true,
            "wynnbet_spreads"   : true,
            "betmgm_spreads"    : true,
            "bovada_spreads"     : true,
            "pointsbet_spreads" : true,
            "caesars_spreads"   : true,
            "mybookie_spreads"  : true,
            
            "predicted_totals"  : false,
            "best_odds_totals"  : false,
            "draftkings_totals" : false,
            "fanduel_totals"    : false,
            "wynnbet_totals"    : false,
            "betmgm_totals"     : false,            
            "bovada_totals"      : false,
            "pointsbet_totals"  : false,
            "caesars_totals"    : false,
            "mybookie_totals"   : false,
            
            
        },
        "totals" : {
            "predicted_spreads" : false,
            "best_odds_spreads"  : false,
            "draftkings_spreads" : false,
            "fanduel_spreads"   : false,
            "wynnbet_spreads"    : false,
            "betmgm_spreads"    : false,
            "bovada_spreads"     : false,
            "pointsbet_spreads" : false,
            "caesars_spreads"   : false,
            "mybookie_spreads"  : false,
    
            "predicted_totals"  : true,
            "best_odds_totals"  : true,
            "draftkings_totals" : true,
            "fanduel_totals"    : true,
            "wynnbet_totals"    : true,
            "betmgm_totals"     : true,
            "bovada_totals"      : true,
            "pointsbet_totals"  : true,
            "caesars_totals"    : true,
            "mybookie_totals"   : true,
            
        }
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [filteredPredictionsData,setFilteredPredictionsData] = useState(predictionData);
    useEffect(() => {
        setFilteredPredictionsData(predictionData);
    }, [predictionData,loading]);

    const [marketSelection,setMarketSelection] = useState("spreads");
    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict[marketSelection]);

    const handleColumnVisibilityToggle = (event) => {
        setMarketSelection(event.target.value);

        updateColumnVisibilityResponsive(event.target.value);
    }

    const updateColumnVisibilityResponsive = (localMarketSelection) => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict[localMarketSelection]);
        }
        else {
            let localColumnVisibility = columnVisibilityDict[localMarketSelection];
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    const handleExportData = () => {
        // TODO - Export original API data, not rows
    };

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
      () => [
        {
            // Set the row value for search, display is overriden by Cell below
            accessorFn: (row) => row.teams.away_display_name + " " + row.teams.home_display_name, 
            id: 'teams',
            header: 'Games',
            size: largeScreen ? 225 : 150,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "body1" : "body2"}>
                        {dayjs(date).format('MMM Do, YYYY')}
                    </Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <GamesDisplay teams={row.original.teams} league={league} largeScreen={largeScreen} />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.spreads, 
            id: 'predicted_spreads',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <SpreadBlockDisplay spreads={row.original.predicted_odds.spreads} 
                        awayHasValue={row.original.predicted_odds_values.spreads.away.value_bool}
                        homeHasValue={row.original.predicted_odds_values.spreads.home.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.totals, 
            id: 'predicted_totals',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "body2"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <TotalBlockDisplay totals={row.original.predicted_odds.totals} 
                        overHasValue={row.original.predicted_odds_values.totals.over.value_bool} 
                        underHasValue={row.original.predicted_odds_values.totals.under.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_spreads',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsSpreadBlockDisplay best_odds_spreads={row.original.best_odds.spreads} />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_totals',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay best_odds_totals={row.original.best_odds.totals} />
                </>
        },
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].spreads, 
            id: `${sportsbook['key']}_spreads`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <SpreadBlockDisplay spreads={row.original.sportsbooks[sportsbook.display].spreads} 
                    bestSportsbookAway={row.original.best_odds.spreads.away.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookHome={row.original.best_odds.spreads.home.sportsbooks.includes(sportsbook.display)}
                    />
                </>
            )
            }
        ))
        ,
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].totals, 
            id: `${sportsbook['key']}_totals`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display].totals} 
                    bestSportsbookOver={row.original.best_odds.totals.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.totals.under.sportsbooks.includes(sportsbook.display)}
                    />
                </>
            )
            }
        ))

        ],
        [largeScreen]
    );

    useEffect(() => {
        updateColumnVisibilityResponsive(marketSelection);
    }, [largeScreen,marketSelection,sportsbookSelection]);

    return (
    <>
      <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
        <BasePredictionResultsMRT

            columns={columns}
            data={filteredPredictionsData}

            loading={loading}
            theme={theme}
            largeScreen={largeScreen}

            // Top Toolbar custom actions
            renderTopToolbarCustomActions={({ table }) => {

                return (
                <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                    {largeScreen && 
                    // The market selection dropdown is moved into the Filter
                    // for small screens
                    <MarketSelectionDropdown 
                    marketSelection={marketSelection} 
                    onChangeFn={handleColumnVisibilityToggle} 
                    width={150}
                    />
                    }
                    <TeamPredictionResultsFilter 
                        marketSelection={marketSelection}
                        sportsbookSelection={sportsbookSelection}
                        setSportsbookSelection={setSportsbookSelection}
                        largeScreen={largeScreen}
                        handleColumnVisibilityToggle={handleColumnVisibilityToggle}
                        predictionData={predictionData}
                        setFilteredPredictionsData={setFilteredPredictionsData}
                    />
                    <TeamPredictionResultsSortBy 
                        filteredData={filteredPredictionsData}
                        setFilteredData={setFilteredPredictionsData}
                        marketSelection={marketSelection}
                    />
                    <ExportPredictionsButton handleExportData={handleExportData} />
                </Box>
                );
            }}
            
            state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true }}
            
            onColumnVisibilityChange={ setColumnVisibility }

            // Column Pinning
            enableColumnPinning={false}
            layoutMode='grid-no-grow'
            initialState= {{
                columnPinning: { left: ['teams','predicted_spreads','predicted_totals'] },
                showGlobalFilter: true
            }}


          /> {/* End of table definition */}

        </Box>
    </>
    )
}


function GamesDisplay({teams,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;
    const innerStackSpacing = largeScreen ? 1 : 0.75;

    return (
        <>
            <Stack direction="column" height="100%" width="100%" justifyContent="center" spacing={1}>
                <Stack height="50%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={teams.away} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? teams.away_display_name : teams.away_display_name_abbreviated}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {teams.away_prediction}
                        </Typography>
                    </Stack>
                </Stack>
                
                <Stack height="50%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={teams.home} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? teams.home_display_name : teams.home_display_name_abbreviated}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {teams.home_prediction}
                        </Typography>
                    </Stack>
                    
                </Stack>
            </Stack>
        </>
    )
}