

export const MAX_MODEL_NAME_LENGTH = 20;

// Definition should match flask-api/odds/oddsConfig
// class variable props_without_odds
export const PROPS_WITHOUT_ODDS = [
    "Receiving Touchdowns",
    "Rushing Touchdowns"
]

//
// Matching backend definition file is flask-api/config/hyperparameters.py
// There won't be matching definitions for the steps. That is needed on the frontend
// only and is determined by the max and min.
//
export const MIN_NODES = 8;
export const MAX_NODES = 64;
export const NODES_STEPS = 4;

export const MIN_REGULARIZATION = 0.0;
export const MAX_REGULARIZATION = 0.5;
export const REGULARIZATION_STEPS = 0.05;

export const MIN_EPOCHS = 20;
export const MAX_EPOCHS = 500;
export const EPOCHS_STEPS = 20;

export const MIN_LEARNING_RATE = 0.0001;
export const MAX_LEARNING_RATE = 0.1;
export const LEARNING_RATE_STEPS = 0.0001;

export const MIN_VALIDATION_SPLIT = 0.05;
export const MAX_VALIDATION_SPLIT = 0.5;
export const VALIDATION_SPLIT_STEPS = 0.05;