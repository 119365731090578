
import {
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
  } from '@mui/material';

export const selectAllFaq = (
    <Stack direction="column" spacing={1}>
    <Typography>
        No. Selecting all stats won’t be the most accurate model. Too many
        features in a model can lead to overfitting. Meaning that the model will become really good
        at what it knows from the past, but not as good at predicting future games/props.
    </Typography>
    <Typography>
        An example would be logo color. We could add that as a stat for you to use, but we all 
        know that logo color would not help a model predict games/props, and it might actually lead
        to more inaccurate predictions.
    </Typography>
    </Stack>
);

export const opponentExplanation = (
    <Stack direction="column" spacing={1}>
        <Typography variant="body">
            This is best explained with an example. Let's use the Bills vs Chiefs as an example. We setup our models
            to predict the score of a single team for whoever their opponent is. Then we utilize the predictions
            of each team to generate spreads/totals for a game. Below outlines how Passing Yards 
            is viewed when predicting the Bills points in this matchup. And all our stats are cumulative for the 
            season the team is playing in of course.
        </Typography>
        <List sx={{ pl: 3 }}>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Passing Yards" 
                secondary={"The passing yards of the Bills (team we are predicting)"}
                />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Passing Yards Allowed" 
                secondary="The passing yards allowed by the Bills (team we are predicting)"
                />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Opponent Passing Yards" 
                secondary="The passing yards by the Chiefs (team's opponent)"
                />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <ListItemText 
                primary="Opponent Passing Yards Allowed" 
                secondary="The passing yards allowed by the Chiefs (team's opponent)"
                />
            </ListItem>
        </List>
        <Typography variant="body">
            You could select any combination of these 4 variations of Passing Yards, and the same goes for the rest
            of our stats.
        </Typography>
    </Stack>
)