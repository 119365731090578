import { Stack,Typography } from '@mui/material';
import { LeagueIcon } from '../../../../config/leagues';

// Currently unused
const subtitle = 'We do all of the work of organizing data for machine learning \
        and just allow you to select the stats you want your model to use';

function CreateModelHeader({league,modelType}){

    let title = league;
    if (modelType === 'team') {
        title = `${league} Team Model`;
    } else {
        title = `${league} Player Prop Model`;
    }

    const titleVariant = "h4";
    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{pl: {xs:0,md:1},mb: 3 }}>
            <Typography variant={titleVariant}>
                {/* 
                The margin-bottom applied to the icon below is hackery to get it to center within a typography
                component. It is the only way I could get it to work while maintaining size scalability that matches
                the typography variant.
                */}
                <LeagueIcon league={league} sx={{fontSize: 'inherit',verticalAlign: 'sub',color: 'secondary.main', mb: 0.25}} />
            </Typography>
            <Typography variant={titleVariant}>{title}</Typography>
        </Stack>
    )
}

export function CreateTeamModelHeader({league}){

    return (
        <CreateModelHeader
        league={league}
        modelType={'team'}
        />
    )
}

export function CreatePlayerModelHeader({league}){

    return (
        <CreateModelHeader
        league={league}
        modelType={'player'}
        />
    )
}

