import { useEffect, useMemo, useState } from 'react';
import {
  Box, 
  Button,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

import { StatSelectionMRT, tableHeight } from '../../../../components/table';

import { teamSlimSummaryRequest, teamStatisticsRequest, teamTrainRequest } from '../../../../hooks/api/team';
import { SelectedStatsContainer, CategoriesStack } from '../common/stat-selection';
import { HyperParametersContent } from '../common/hyperparameters';
import { SomethingWentWrongPopup } from '../../../../components/responsive';

export function TeamStatSelection({league,editEnabled,setEditEnabled,setUserPopupOpen,setPricingPopupOpen}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  
    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [statsData, setStatsData] = useState([]);
    const [categories,setCategories] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [loading,setLoading] = useState(true);
    const [isTraining, setIsTraining] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [customHyperparametersEnabled,setCustomHyperparametersEnabled] = useState(false);
    const [hyperparametersFetched,setHyperparametersFetched] = useState(false);
    const [hyperparameters,setHyperparameters] = useState(
      {
          "layer1_nodes": 16,
          "layer1_activation": "relu",
          "layer1_kernel_regularizer_l1": 0.0,
          "layer1_kernel_regularizer_l2": 0.0,
          "layer1_bias_regularizer_l1": 0.0,
          "layer1_bias_regularizer_l2": 0.0,
          "layer1_activity_regularizer_l1": 0.0,
          "layer1_activity_regularizer_l2": 0.0,

          "useLayer2": true,
          "layer2_nodes": 16,
          "layer2_activation": "relu",
          "layer2_kernel_regularizer_l1": 0.0,
          "layer2_kernel_regularizer_l2": 0.0,
          "layer2_bias_regularizer_l1": 0.0,
          "layer2_bias_regularizer_l2": 0.0,
          "layer2_activity_regularizer_l1": 0.0,
          "layer2_activity_regularizer_l2": 0.0,

          "learning_rate": 0.001,
          "optimizer": "Adam",
          "loss_function": "huber",
          "loss_metrics": [
              "mean_absolute_error"
          ],
          "train_batch_size": 500,
          "epochs": 200,
          "validation_split": 0.2,

          "train_verbose_enabled": false,
          "test_verbose_enabled": false,

          "test_batch_size": 100

      }
    );
  
    ////////////////////////////////////////////////////////
    // API
    ////////////////////////////////////////////////////////
  
    const handleStatisticsResponse = (response) => {
      const apiStatsData = response.data.stats;
      setStatsData(apiStatsData);
      setCategories(response.data.categories);

      // Only stop loading if not going to continue with processing
      if (editEnabled === false) {
        setLoading(false);
      }
      
    }
    const handleStatisticsError = (error) => {
      // Only stop loading if not going to continue with processing
      if (editEnabled === false) {
        setLoading(false);
      }
    }

    const handleSummaryResponse = (response) => {
      const statRowSelection = {};
      for (let i = 0; i < response.data.selected_stats.length; i++) {
        statRowSelection[response.data.selected_stats[i]] = true;
      }
      setRowSelection(statRowSelection);

      // Set hyperparameters if custom values were used
      // Not checking === true right now because this field does not exist, in production
      // code this should check if true
      if (response.data.custom_hyperparameters_enabled) {
        setHyperparameters(response.data.hyperparameters);
        setHyperparametersFetched(true); // Set this to prevent a re-fetch of defaults when we enable custom hyperparameters
        setCustomHyperparametersEnabled(true);
      }

      setLoading(false);
    }
    const handleSummaryError = (error) => {
      setLoading(false);
    }
  
    useEffect(() => {
      setLoading(true);
      const params = {
        league: league
      }
      teamStatisticsRequest(params,handleStatisticsResponse,handleStatisticsError);

      // Wait until stats have been loaded to populate selected stats
      if (editEnabled === true) {
        teamSlimSummaryRequest(handleSummaryResponse,handleSummaryError);

        // Disable edit mode once stats have been populated
        // To be honest, this is bad design but I couldn't get it to work any other way because
        // they share the same page, so location wasn't triggering re-renders
        setEditEnabled(false);
      }
      else {
        setRowSelection({});
      }
    }, [league]);

    
  
    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const columns = useMemo(
      () => [
        {
          accessorKey: "statistic",
          header: "Statistic",
          size: isMobile ? 1 : 200,
        },
        { // Combine as string for filtering/searching
          // but actual display is defined by Cell parameter.
          // Need to use row.orignal rather than Cell or renderedCellValue.
          accessorFn: (row) => row.category.join(','), 
          id: 'category',
          header: 'Category',
          filterVariant: 'multi-select',
          filterSelectOptions: categories,
          Cell: ({ row }) =>
            <CategoriesStack row={row} isMobile={isMobile} />
        }
      ],
      [categories,isMobile]
    );
  
    ////////////////////////////////////////////////////////
    // Run button handler function
    ////////////////////////////////////////////////////////
  
    const navigate = useNavigate();
  
    const handleTrainResponse = (response) => {
      setIsTraining(false);
      navigate('/model-summary/team');
    }
  
    const handleTrainError = (error) => {
      if (error.response) {
        if (error.response.data.csrf) {
          setUserPopupOpen(true);
        } else if (error.response.status == 401 ) {
          // Unauthorized
          setPricingPopupOpen(true);
        }
        else {
          // Something went wrong
          setSomethingWentWrong(true);
        }
        // Always stop loading
        setIsTraining(false);
      }
    }
  
    const handleRunButtonClicked = () => {
      setIsTraining(true); // Set training state to true
      const params = {
        league: league,
        custom_hyperparameters_enabled: customHyperparametersEnabled
      }
      const requestBody = JSON.stringify({
        selectedStats: Object.keys(rowSelection),
        hyperparameters: hyperparameters
      })
      
      teamTrainRequest(requestBody,params,handleTrainResponse,handleTrainError);
    };
  
    
    // Styling definitions
    const gridPadding = {xs:0.2,sm: 1};
  
    return (
      <>
        <Grid container rowSpacing={3} direction={largeScreen ? "row" : "column"} justifyContent="center" sx={{mb: 4}}>
          <Grid item xs={4} sx={{ p: gridPadding }}>
            <SelectedStatsContainer rowSelection={rowSelection} setRowSelection={setRowSelection} isTraining={isTraining} />
          </Grid>
  
          <Grid item xs={8} justifyContent="center" sx={{ p: gridPadding }}>
            {/* Stat Selection Table */}
            <Box component='div' className='onboarding-step-1'>
              <StatSelectionMRT
                columns={columns}
                data={statsData}
  
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
  
                loading={loading}
                height={tableHeight}
  
                isMobile={isMobile}
                theme={theme}
  
                getRowId= {(row) => row.statistic}
  
                mrtTheme={isMobile && {
                    baseBackgroundColor: theme.palette.background.default
                }}
  
                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {
                return (
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                        color={theme.primary}
                        variant="contained"
                        onClick={handleRunButtonClicked}
                        disabled = {Object.keys(rowSelection).length === 0}
                        className="onboarding-step-4"
                    >
                        Run
                    </Button>
                    </div>
                );
                }}
  
                />
            </Box>
          </Grid>
  
          <Grid container>
            <Grid item xs={12} sx={{p: gridPadding }} className="onboarding-step-3">
              <HyperParametersContent 
              league={league}
              modelType={"team"}
              enabled={customHyperparametersEnabled}
              setEnabled={setCustomHyperparametersEnabled}
              hyperparameters={hyperparameters}
              setHyperparameters={setHyperparameters}
              dataFetched={hyperparametersFetched}
              setDataFetched={setHyperparametersFetched}
              setUserPopupOpen={setUserPopupOpen}
              setSomethingWentWrong={setSomethingWentWrong}
              />
            </Grid>
          </Grid>
        </Grid>
        <SomethingWentWrongPopup open={somethingWentWrong} setOpen={setSomethingWentWrong} />
        </>
      // </Grid>
    );
  }