import {
    Box
} from '@mui/material';
  
import imageSrc from '../../assets/SolvedSportsLogo.webp';

export function SolvedSportsLogo(props){

    return (
        <Box
            component="img"
            alt="logo"
            src={imageSrc}
            {...props}
        />
    )
}