import { 
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PropTypes from 'prop-types';

export function ResponsiveContainer(props)
{
  const {children,sx,...otherProps} = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
        maxWidth="xl"
        {...otherProps}
        sx={{
            mt: 4,
            mb: 4,
            ...(isMobile && {
            pl: 1,
            pr: 1
            }),
            ...sx
        }}
    >
      {children}
    </Container>
  )
}

export function ResponsiveDialog(props) {

  const {children,sx,...otherProps} = props;
  const theme = useTheme();

  return (
      <Dialog
      sx={{
          '& .MuiPaper-root': {
              // The actual popup styling
              width: {xs: '90%',md: 500},
              backgroundColor: theme.palette.background.paper
          },
          '& .MuiDialogContent-root': {
              padding: 2,
          },
          '& .MuiDialogActions-root': {
              padding: 2,
          },
          ...sx
      }}
      
      {...otherProps}
      >
          {children}
      </Dialog>
  )
}

export function SomethingWentWrongPopup({open,setOpen}){

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <ResponsiveDialog
    onClose={handleClose}
    open={open}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningIcon color="warning" sx={{height: 40,width: 40}} />
          <Typography variant="h5">Something Went Wrong</Typography>
        </Stack> 
      </DialogTitle>
      <IconButton
          onClick={handleClose}
          sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "primary.main",
          }}
      >
          <CloseIcon />
      </IconButton>
      <DialogContent>
        We are not quite sure what happened, but something broke...
      </DialogContent>
      
    </ResponsiveDialog>
  )
}

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <>
      {value === index && <Box sx={{height: '100%',width:'100%'}}>{children}</Box>}
      </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};