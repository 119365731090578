
import {Walkthrough} from '../../../components/onboarding/walkthroughs';

export function ModelSummaryPageWalkthrough() {

    const steps = [
        {
          "target"  : ".onboarding-step-1",
          "content" : "The performance metrics of the model you created. Overall grade compares your model to other users, and evaluates it's accuracy.",
          "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-2",
            "content" : "Your model is backtested for historical profitability. Refer to info button and FAQs for more info.",
            "disableBeacon": true
          },
        {
          "target"  : ".onboarding-step-3",
          "content" : "The statistics you chose for this model",
          "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-4",
            "content" : "Analysis plots of model performance. Refer to FAQs below for more info.",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-5",
            "content" : "Save model to profile",
            "disableBeacon": true
        },
        {
            "target"  : ".onboarding-step-6",
            "content" : "Run predictions on future or past games right from here",
            "disableBeacon": true
        },
        {
          "target"  : ".onboarding-step-7",
          "content" : "Optimize your model by continuing to tinker",
          "disableBeacon": true
      }
      ]

      return (
        <>
            <Walkthrough 
                steps = {steps}
                preference={'model_summary_page_onboarding_enabled'}
            />
        </>
      )

}