import { Helmet } from 'react-helmet-async';

export const SeoHelmet = ({ title, description, url=null, type="website", name="@SolvedSports" }) => {

    // Relative path to public folder
    const OgImageSrc = process.env.REACT_APP_BASE_URL + "/og-banner-image.png";
    const TwitterImageSrc = process.env.REACT_APP_BASE_URL + "/twitter-banner-image.png";

    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name="description" content={description} />

            {url && <link rel="canonical" href={url} />}

            {/* Open Graph Metadata */}
            {/* Reference: https://ogp.me/ */}
            <meta property="og:title" content={title} />
            <meta property="og:type" content={type} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={OgImageSrc} />
            <meta property="og:image:secure_url" content={OgImageSrc} />
            <meta property="og:image:width" content={1200} />
            <meta property="og:image:height" content={630} />
            {url && <meta property="og:url" content={url} />}

            {/* Twitter Metadata */}
            {/* Reference: https://developer.x.com/en/docs/x-for-websites/cards/overview/markup */}
            <meta name="twitter:card" content={type} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />

            {/* Must be less than 5 MB. JPG, PNG, WEBP and GIF supported */}
            <meta name="twitter:image" content={TwitterImageSrc} />
            <meta name="twitter:image:width" content={508} />
            <meta name="twitter:image:height" content={266} />
            <meta name="twitter:image:alt" content={title} />
        </Helmet>
    );
};
