import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';

import { 
    Button,
    Box,
    Chip,
    Grid,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';

import { ordinal_suffix_of } from '../../../utils/general';
import { OverallGradeCircle } from '../../../components/metric-displays';
import { DashboardItem } from './dashboard-containers';
import { CustomTabPanel } from '../../../components/responsive';
import { ChartTabs } from './plotting';
import { ModelSummarySavePopup } from './save-popup';
import { perc2color } from '../../../utils/styling-support';
import { TeamsPredictionsPopup, PlayerPredictionsPopup } from '../../predictions/components/common/prediction-popups';
import { ModelSummaryPageWalkthrough } from './walkthrough';
import { SuccessSnackbar } from '../../../components/snackbars';
import { teamSummaryRequest } from '../../../hooks/api/team';
import { playerSummaryRequest } from '../../../hooks/api/player';
import { TeamBetHistory,PlayerBetHistory } from './historical-profitability';
import { DefaultTooltip } from '../../../components/buttons';
import { overallGradeInfo } from '../../../utils/common-copy';
import { BlurContent, ContentLock } from '../../../components/content-lock';
import { GeneralBlock,LayerBlock } from '../../create-model/components/common/hyperparameters';

export function ModelSummaryDashboard({modelType}){
    
    const theme = useTheme();
    const navigate = useNavigate();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [modelSummaryData,setModelSummaryData] = useState({});
    const [modelSummaryDataLoaded,setModelSummaryDataLoaded] = useState(false);
    const [predictionsPopupOpen, setPredictionsPopupOpen] = useState(false);

    const loadPlottingData = true;

    ////////////////////////////////////////////////////////
    // API
    ////////////////////////////////////////////////////////

    const handleResponse = (response) => {
        setModelSummaryData(response.data);
        setModelSummaryDataLoaded(true);
    }
    const handleError = (error) => {
        if (error.response) {
            
            // CSRF rejection = 400
            // Model not found in session = 404
            if (error.response.data.csrf || error.response.status === 404) {
                navigate('/');
            }
            
            // 500
            else {
                navigate('/');
            }
        }

        // No response
        else {
            navigate('/');
        }
    }

    useEffect(() => {
        if (modelType === 'team'){
            teamSummaryRequest(handleResponse,handleError);
        } else if (modelType === 'player'){
            playerSummaryRequest(handleResponse,handleError);
        }
    }, [loadPlottingData]);

    const handleEditClicked = () => {
        // Redirect to proper create model page based on modelType and league
        // Model id already set by being on summary page, so just need to redirect
        navigate(`/create-${modelType}-model/${modelSummaryData.league}`, {state : {edit_enabled : true}})
    }

    return (
        <>
        <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography variant="h4" sx={{textTransform: 'capitalize'}}>{modelSummaryData.league} {modelType} Model Summary</Typography>
            {modelType=='player' && modelSummaryDataLoaded ?
            (<>
                <Stack alignItems='center' sx={{width:"100%"}}>
                    <Stack direction="row" spacing={{xs:1,sm:3}} sx={{mt:1.5,width: {xs:"90%",sm:"40%"},minWidth: 250}}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="Position"
                            label="Position"
                            name="Position"
                            value={modelSummaryData.position}
                            disabled={true}
                            size={largeScreen ? "medium" : "small"}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="Prop"
                            label="Prop"
                            name="Prop"
                            value={modelSummaryData.prop}
                            disabled={true}
                            size={largeScreen ? "medium" : "small"}
                        />
                    </Stack>
                </Stack>
            </>)
            :
            (<></>)
            }
        </Box>
        <Stack direction="row" justifyContent={{xs:"center",sm:"flex-end"}} sx={{width: "100%",mt:{xs:0,sm:-4} }}>
            <ModelSummaryPageWalkthrough />
        </Stack>
        {modelSummaryDataLoaded ?
        (
            <>
            <Grid container columnGap={1} rowGap={2} direction={largeScreen ? "row":"column"} justifyContent="center">
                <Grid item xs>
                    <Stack direction="column" spacing={2} sx={{height: "100%"}}>
                        <Stack direction={{xs:"column",sm:"row"}} alignItems="center" justifyContent="center" spacing={{ xs: 1, sm: 2 }} sx={{width: '100%'}}>
                            <SaveButton saved={modelSummaryData.saved} name={modelSummaryData.model_name} modelType={modelType} league={modelSummaryData.league} width={{xs:"80%",sm:"50%"}}
                                className='onboarding-step-5' position={modelSummaryData.position} prop={modelSummaryData.prop} />
                            <Stack direction={{xs:"column",sm:"row"}} alignItems="center" justifyContent="center" spacing={{ xs: 1, sm: 2 }} width={{xs:"80%",sm:"50%"}}>
                                <Button
                                sx={{ width: "100%" }} 
                                className='onboarding-step-6'
                                onClick={() => {setPredictionsPopupOpen(true)} }
                                startIcon={<CalendarMonthIcon />}
                                >
                                    Predict
                                </Button>
                                <Button
                                disabled={modelSummaryData.expert}
                                sx={{ width: "100%" }}
                                className='onboarding-step-7'
                                startIcon={<EditIcon />}
                                onClick={()=>handleEditClicked()}
                                >
                                    Edit
                                </Button>
                            </Stack>
                        </Stack>
                        <MetricsSummary
                        overall_grade={modelSummaryData.overall_grade}
                        test_loss={modelSummaryData.test_loss}
                        loss_percentile={modelSummaryData.loss_percentile}
                        rvalue={modelSummaryData.rvalue}
                        rvalue_percentile={modelSummaryData.rvalue_percentile}
                        />
                        <HistoricalProfitabiltiyDisplay 
                        historical_profitability={modelSummaryData['historical_profitability']} 
                        access_allowed={modelSummaryData['historical_profitability_access']}
                        valid_prop={modelSummaryData['historical_profitability_valid_prop']} // Will be null if team and that is fine
                        modelType={modelType}
                        />
                    </Stack>
                </Grid>
                <Grid item xs>
                    <Stack direction="column" spacing={2} height="100%">
                        <DashboardItem className='onboarding-step-4' sx={!largeScreen && {height: 300}}>
                            <ChartTabs modelPlottingData={modelSummaryData}/>
                        </DashboardItem>
                        <ChosenStats selectedStats={modelSummaryData.selected_stats} className='onboarding-step-3'/>
                    </Stack>
                </Grid>
                {modelSummaryData.custom_hyperparameters_enabled &&
                <>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Hyperparameters
                    </Typography>
                </Grid>
                <Grid container columnGap={1} rowGap={2} direction={largeScreen ? "row":"column"}>
                    <Grid item xs>
                        <LayerBlock 
                        layerNumber={1} 
                        hyperparameters={modelSummaryData.hyperparameters} 
                        setHyperparameters={null}
                        enabled={false}
                        />
                    </Grid>
                    <Grid item xs>
                        <LayerBlock 
                        layerNumber={2} 
                        hyperparameters={modelSummaryData.hyperparameters} 
                        setHyperparameters={null}
                        enabled={false}
                        />
                    </Grid>
                </Grid>
                <Grid item xs>
                    <GeneralBlock
                    hyperparameters={modelSummaryData.hyperparameters} 
                    setHyperparameters={null}
                    enabled={false}
                    />
                </Grid>
                </>
                }
            </Grid>

            {/* Popups */}
            {/* Model Index not used in this context but needed for component
                Render popup type based on model type. Components can't be commonized.
            */}
            {modelType == 'team' ?
            (<>
                <TeamsPredictionsPopup league={modelSummaryData.league} modelIndex={0} 
                open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen} setModelId={false}
            />
            </>)
            :
            (
                <>
                <PlayerPredictionsPopup league={modelSummaryData.league} position={modelSummaryData.position} prop={modelSummaryData.prop} 
                modelIndex={0} open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen} setModelId={false}
                />
                </>
            )}
            </>
        ):
        (
            <>
            <Grid container columnGap={1}rowGap={2}  direction={largeScreen ? "row":"column"} justifyContent="center" sx={{height: 400}}>
                <Grid item xs>
                    <Stack direction="column" spacing={1} sx={{width: '100%',height: "100%"}}>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{width: '100%',height: "10%"}}>
                            <Skeleton variant="rectangular" animation="wave" width={"50%"} height={"100%"}/>
                            <Skeleton variant="rectangular" animation="wave" width={"50%"} height={"100%"}/>
                        </Stack>
                        <Skeleton variant="rectangular" animation="wave" height={"90%"}/>
                    </Stack>
                </Grid>
                <Grid item xs sx={{backgroundColor: `${theme.palette.background.paper}`, borderRadius: 2 }}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={"100%"}/>
                </Grid>
            </Grid>
            </>
        )
        }              

        </>
    );
}

export const ContextRow = ({ name, value, enableInfo = false, info = "", bgColor = "" }) => {

    return (
        <>
            <Stack direction="column" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1">{name}</Typography>
                    {enableInfo && <DefaultTooltip text={info} />}
                </Stack>
                <Chip key={name} label={value}
                    sx={{ typography: 'body1', backgroundColor: bgColor }}
                />
            </Stack>
        </>
    );
}

function SaveButton({saved,name,modelType,league,width,className="",position="",prop=""}) {

    const navigate = useNavigate();

    // State set as prop value on page loading. If the model has already been saved,
    // this will disable the button and show it has been saved.
    const [modelIsSaved,setModelIsSaved] = useState(saved);


    // State only actually used when a model is saved to grab its name during saving
    // and display it. If a model is already saved, this is simply duplicating the name
    // input.
    const [modelName,setModelName] = useState(name);

    const [saveModelPopupOpen,setSaveModelPopupOpen] = useState(false);

    // If the model is already saved, don't show the snack bar. Only show the snack bar
    // on the original saving of the model
    const [snackBarOpen,setSnackBarOpen] = useState(!modelIsSaved);

    function handleSnackBarView() {
        const tab = modelType == 'team' ? 'Team Models' : 'Player Models';
        navigate('/profile',{state : {selectedTab : tab}})
    }

    return (
        <>
            { modelIsSaved ? (
              <>
                  <Button variant="contained" disabled sx={{width: width}} className={className} 
                  endIcon={<CheckIcon />}
                  >
                    {modelName}
                  </Button>
                  <SuccessSnackbar open={snackBarOpen} setOpen={setSnackBarOpen}
                        message={"Successfully saved model to account"} 
                        viewFunction={handleSnackBarView}
                    />
              </>
              
              ) : (
                <>
                    <Button variant="contained" startIcon={<SaveIcon />} onClick={() => setSaveModelPopupOpen(true)} sx={{width: width}} className={className}>Save</Button>
                    <ModelSummarySavePopup open={saveModelPopupOpen} setOpen={setSaveModelPopupOpen} setModelIsSaved={setModelIsSaved} setModelName={setModelName}
                    league={league} modelType={modelType} position={position} prop={prop} />
                    
                </>
                )}
        </>
        
    );
}

function ChosenStats({selectedStats,className=""}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return(
        <DashboardItem title={"Chosen Statistics (" + selectedStats.length +")"} maxHeight={300}>
            <Stack alignItems="center" sx={{ width: '100%', minHeight: '0', maxHeight: 200, flexGrow: 1 }} className={className}>

                <Box component="div" sx={{ p: 1, width: '100%', height: '100%', overflowY: 'scroll', borderRadius: 2,
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))`,
                    position: 'relative'}}>
                    {/* <BlurContent opaque={true} /> */}
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                
                    {selectedStats.map((item,index) => (
                        <Chip key={index}
                        label={item}
                        size={isMobile ? "small" : "medium"}
                        sx={ isMobile ? {
                            height: 'auto',
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal',
                            },
                        } : {}
                        }
                        />
                    ))}
                
                    </Stack>
                </Box>
            </Stack>
        </DashboardItem>
      );
}

function HistoricalProfitabiltiyDisplay(
    {historical_profitability,access_allowed,valid_prop,modelType}
){

    const [value, setValue] = useState(1);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyVariant = isMobile ? "caption" : "body";

    const tooltipText = (
    <Stack direction="column" spacing={1}>
        <Typography variant="body">
            Backtested on real historical results and odds. "All" refers to all
            bets included in the historical profitability calculations. "Value" refers
            to only the bets your model and our value formulas recommend.
        </Typography>
        <Typography variant="body">
            Refer to FAQs below for more information
        </Typography>
    </Stack>
    );

    // all vs value
    // Refer to FAQs below for more information

    return (
        <DashboardItem title={"Historical Profitability"} tooltip={<DefaultTooltip text={tooltipText} />} className='onboarding-step-2'>
            {modelType === 'team' ?
                <Stack direction="column" spacing={1} sx={{mt: -1,position: 'relative'}} width="100%" height="100%">
                    <ContentLock open={!access_allowed} opaque={true} />
                    <Tabs value={value} onChange={handleChange} centered>
                            <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>All</Typography>} value={0} />
                            <Tab sx={{minWidth: 20,p:{xs:1}}} label={<Typography variant={typographyVariant}>Value</Typography>} value={1} />
                    </Tabs>
                    <Stack direction="column" spacing={2}>
                        <CustomTabPanel value={value} index={0}>
                            <TeamBetHistory bets_tracker={historical_profitability['all_bets']} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <TeamBetHistory bets_tracker={historical_profitability['value_bets']} />
                        </CustomTabPanel>
                    </Stack>
                </Stack>
            
            :
                <Stack width="100%" height="100%" sx={{position: 'relative'}}>
                    <ContentLock open={!access_allowed} opaque={true} />
                    <PlayerBetHistory historical_profitability={historical_profitability} />
                    {valid_prop === false &&
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{mt: 1}}>
                        <WarningIcon color="warning"/>
                        <Typography variant="body2">This prop does not offer historical profitability</Typography>
                    </Stack>
                    }
                </Stack>
                
            }
            
            </DashboardItem>
        )
}

function OverallGradeTooltip(){

    const tooltipText = (
        <Stack direction="column" spacing={1}>
            <Typography variant="body">
                {overallGradeInfo}
            </Typography>
            <Typography variant="body">
                Note: This grade can change over time because it depends on accuracy of models that other users
                create.
            </Typography>
        </Stack>
    )

    return (
        <DefaultTooltip text={tooltipText} />
    )
}

function MetricsSummary({overall_grade,test_loss,loss_percentile,rvalue,rvalue_percentile}){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const grade_circle_dim = isMobile ? 120 : 150;

    return (
        <Stack direction="row" alignItems="stretch" spacing={2} className='onboarding-step-1'>
            <DashboardItem title={"Overall Grade"} tooltip={<OverallGradeTooltip />} sx={{height: 'auto'}}>
                <Stack direction="column" alignItems="center" justifyContent="center">
                    <Box sx={{maxHeight: grade_circle_dim,maxWidth: grade_circle_dim}}>
                        <OverallGradeCircle grade={overall_grade} />
                    </Box>
                </Stack>
            </DashboardItem>
            <DashboardItem>
                <Stack direction="column" spacing={2} width="100%"
                >
                    {ContextRow({
                        name: "Prediction Error",
                        value: test_loss
                    })}
                    {ContextRow({
                        name: "Error Rank",
                        value: ordinal_suffix_of(Math.round(loss_percentile * 100)) + " %",
                        bgColor: perc2color(loss_percentile,theme)
                    })}
                    {ContextRow({
                        name: "R Value",
                        value: rvalue
                    })}
                    {ContextRow({
                        name: "R Value Rank",
                        value: ordinal_suffix_of(Math.round(rvalue_percentile * 100)) + " %",
                        bgColor: perc2color(rvalue_percentile,theme)
                    })}
                </Stack>
            </DashboardItem>
        </Stack>

    )
}