
import { FlaskClient } from "./setup";

export const teamTrainRequest = (requestBody,params,handleResponse,handleError) => {
    FlaskClient.post('team/train',
      requestBody,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const teamPredictionsRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/predictions',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSummaryRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/summary')
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSlimSummaryRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/summary',
  {
  params: {slim: true}
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamDeleteRequest = (params,handleResponse,handleError) => {
  FlaskClient.delete('team/delete',
  {
  params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamStoreRequest = (params,handleResponse,handleError) => {
  FlaskClient.post('team/store',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamExpertRequest = (params,handleResponse,handleError) => {
  FlaskClient.put('team/expert',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamScheduleRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/schedule',
  {
      params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamUpdateRequest = (params,handleResponse,handleError) => {
  FlaskClient.put('team/update',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSaveRequest = (params,handleResponse,handleError) => {
  FlaskClient.post('team/save',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamModelsRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/models')
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamStatisticsRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/statistics',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamValidDatesRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/valid-dates',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamHyperparametersRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/hyperparameters',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}